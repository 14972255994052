import React, { useState, useEffect } from 'react';
import '../Bookings/AirportAutocomplete.css';

const AirportAutocomplete = ({ id, value, onChange }) => {
  const [inputValue, setInputValue] = useState(value);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchAirportSuggestions = async (input) => {
    const url = `https://Vgroup1.pythonanywhere.com/api/airport_search/?q=${input}`;
  
    setLoading(true);
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Failed to fetch airport suggestions');
      }
      const data = await response.json();
      const formattedSuggestions = data.map(airport => ({
        id: airport.id,
        value: `${airport.iata_code} - ${airport.name}, ${airport.city}`,
        iata_code: airport.iata_code
      }));
      setSuggestions(formattedSuggestions);
      console.log(data);
    } catch (error) {
      console.error('Error fetching airport suggestions:', error);
      setError('Failed to fetch airport suggestions. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    if (inputValue.trim() !== '') {
      fetchAirportSuggestions(inputValue);
    } else {
      setSuggestions([]);
    }
  }, [inputValue]);

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion.value);
    onChange(suggestion.iata_code);
    setSuggestions([]);
  };

  return (
    <div className="airport-autocomplete">
      <div className='airport-autocomplete-input'>
      <input
        type="text"
        id={id}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Enter city or airport"
        className='airport-autocomplete-input'
      />
      </div>

      <ul>
        {suggestions.map((suggestion, index) => (
          <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
            {suggestion.value}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AirportAutocomplete;
