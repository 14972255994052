import React from 'react'
import '../Cancellation/Cancellation.css'


export default function Cancellation() {
  return (
    <div className="privacy-policy-container">
        {/* <h1>Cancellation And Refund Policy</h1> */}
    <h1 className="privacy-policy-title">Flight cancellations</h1>
    <p className="privacy-policy-paragraph">In order to cancel a flight that you’ve already booked with VGroup Ventures , contact our customer service department at +18664104797.</p>
    <p className="privacy-policy-paragraph">
        The itinerary document is sent right after you make your travel reservation. The itinerary lists your travel requests and informs you that VGroup Ventures has transmitted the details of your reservation to the relevant travel supplier(s).
        The e-ticket (or invoice receipt) document is sent once all components of your travel plans have been confirmed by the travel supplier(s). The e-ticket contains your final trip arrangements and is to be used as a proof of booking when traveling.
    </p>




    <h2 className="privacy-policy-subtitle">Here are some notes to keep in mind when changing or canceling an flight:</h2>
    <p className="privacy-policy-paragraph">Generally, you can cancel a flight booked through VGroup Ventures  within 24 hours for a full refund.</p>
    <p className="privacy-policy-paragraph">Generally, changing a flight will result in you being charged fees by the airline. There may also be other rules or restrictions, such as having to book the new flight with the same primary passenger on the same airline, and paying the difference in ticket costs, if the new ticket is more expensive than the old one.</p>
    <p className="privacy-policy-paragraph">Certain flights aren’t subject to refunds. Read the rules and restrictions as part of your itinerary to find out if yours is or not.</p>
    <p className="privacy-policy-paragraph">Even if a flight isn’t subject to a refund, you may be able to cancel it and still receive credit for a future flight from the airline. The earlier you act, the greater the chance that this might happen for you.</p>
    <p className="privacy-policy-paragraph">There are certain smaller airlines that you will need to contact directly if you wish to cancel flights.</p>

   


    <h2 className="privacy-policy-subtitle">REFUND POLICY</h2>
    <p className="privacy-policy-paragraph">If the tour or any part thereof cannot be conducted due to Force Majeure or Vis Majeure, the Company shall not be responsible to give any refund to you.</p>
    <p className="privacy-policy-paragraph">It would take at least minimum of 15 to 45 days to process the refund (if due).</p>


    <h2 className="privacy-policy-subtitle">CONDITIONS OF TRAVEL</h2>



    <p className="privacy-policy-paragraph">
    The client will have to strictly follow the Tour Program. There shall be no refund, if the client fails to join the group at the commencement of the tour, or joins the group later or leaves the group before tour ending. It shall be noted that for all purposes, it shall be the responsibility of the client to reach the place of commencement of the tour and register with the representative of the company at the appointed place, date and time.
    </p>
    <p className="privacy-policy-paragraph">
    In case where a client along with his family is compelled to discontinue the tour due to any reason whatsoever including illness, death or loss of any travel documents, no claim shall be entertained for refund of unutilized services. Even if a client is unable to reach the place of commencement of the tour due to any reason whatsoever including loss of baggage or loss of travel documents, his booking shall be treated as “no show” on the tour and 100% cancellation charges will be levied.
    </p>
    <p className="privacy-policy-paragraph">If a client avails pre – tour services or part thereof, or the air tickets (if booked under any offer) but fails to join the group for the main tour at the appointed place, or cancels the tour after using the air tickets or pre – tour arrangements or part thereof, it shall be treated as “no show” and there will be no refund whatsoever for the unutilized pre-tour or main tour services.
The company reserves the right to withdraw tour membership of any client whose behaviour is deemed likely to affect the smooth operation of the tour or adversely affect the enjoyment or safety of other passengers and the Company shall be under no liability to any such person.
    </p>
    <p className="privacy-policy-paragraph">The immunities provided under this contract shall be available to THE COMPANY’s employees, directors, managers, including, Employees and Agents but not to the Independent Contractors selected by the company.
Each of these conditions shall be severable from the other and if any provision be invalid, illegal or unenforceable, the remaining provisions shall nevertheless have full force and effect. No liability on the part of THE COMPANY arising in any way out of the Contract in respect of any tour, holiday, excursion facilities shall exceed the total amount paid or agreed to be paid for the tour holiday, and shall in no case include any consequential, incidental loss or additional expense whatsoever.
special/high season charge levied by the suppliers, hike in the airline/rail charges before the date of departure and to surcharge accordingly. The Client must pay for all such increases in price in full before the departure.
If no suit/action is brought against THE COMPANY within 7 days of the last day of the tour, THE COMPANY shall be discharged from all liabilities under/or arising out of this contract and the client shall be deemed to have relinquished/abandoned all his rights under or arising from this contract.
    </p>
    <p className="privacy-policy-paragraph">
    In case of publication of any travel scheme offering any discount or benefit by THE COMPANY, we shall have the sole right to withdraw such a scheme or discount at any time unless a specific assurance of the contrary is published.
    </p>
</div>
  )
}
