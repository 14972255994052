import React, { useState } from 'react';
import './PaymentForm.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import cards from '../../Media/cards.png';
import TrustBoxWidget from '../trustpiliot/trustpiliot';

function PaymentForm() {
  const [formData, setFormData] = useState({
    card_no: '',
    exp_date: '',
    cvv: '',
    name_on_card: '',
    billing_address: '',
    city: '',
    state: '',
    zip_code: '',
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCardNumberChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters
    value = value.slice(0, 16); // Limit to 16 digits
    const formattedValue = value.replace(/(\d{4})(?=\d)/g, '$1-'); // Add hyphens after every 4 digits

    // Store the raw 16-digit card number in the state
    setFormData(prevState => ({
      ...prevState,
      card_no: value // Store only the digits
    }));

    // Display the formatted value with hyphens
    e.target.value = formattedValue;
  };

  const handleExpDateChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters
    
    if (value.length >= 2) {
      let month = value.slice(0, 2);
      if (parseInt(month, 10) > 12) {
        month = '12'; // Ensure the month is not greater than 12
      }
      value = `${month}${value.slice(2, 4)}`; // Concatenate the valid month with the rest of the value
    }

    if (value.length >= 3) {
      value = `${value.slice(0, 2)}/${value.slice(2, 4)}`; // Format as MM/YY
    }

    setFormData(prevState => ({
      ...prevState,
      exp_date: value.slice(0, 5) // Limit to MM/YY format
    }));
  };

  const handleCvvChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters
    setFormData(prevState => ({
      ...prevState,
      cvv: value.slice(0, 3) // Limit to 3 digits
    }));
  };


  const gtagSendEvent = (url) => {
    var callback = function () {
      if (typeof url === 'string') {
        window.location = url;
      }
    };
    window.gtag('event', 'conversion_event_purchase', {
      'event_callback': callback,
      'event_timeout': 2000,
    });
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Save card details to local storage
      localStorage.setItem('paymentInfo', JSON.stringify(formData));

      // Capture the event before navigating
      const success = gtagSendEvent('/confirmation');
      if (!success) {
        // If the event failed to send, navigate to the confirmation page
        navigate('/confirmation');
      }
    } catch (error) {
      console.error('Error saving card details:', error);
      // Optionally, handle error (e.g., display an error message)
    }
  };

  return (
    <div className="payment-form">
      <div className="payment-container">
        <div className="payment-details">
          <h2>Payment Details</h2>
          <div className="payment-method">
            <div className="payment-description">
              <h3>Credit/Debit Card</h3>
              <div className="payment-icon">
                <img src={cards} alt="Credit/Debit Card" />
              </div>
              {/* <p>Visa, Master Card, Amex, And More</p> */}
              <TrustBoxWidget/>
            </div>
          </div>
        </div>
        <div className="payment-form-section">
          <form className="payment-form-content" onSubmit={handleSubmit}>
            <div className="form-groups">
              <label htmlFor="card_no">Credit/Debit Card Number*</label>
              <input
                type="text"
                id="card_no"
                name="card_no"
                value={formData.card_no.replace(/(\d{4})(?=\d)/g, '$1-')}
                onChange={handleCardNumberChange}
                placeholder="1234-5678-9012-3456"
                maxLength="19"
                required
              />
            </div>
            <div className="form-groups">
              <label htmlFor="name_on_card">Card Holder's Name</label>
              <input
                type="text"
                id="name_on_card"
                name="name_on_card"
                value={formData.name_on_card}
                onChange={handleChange}
                placeholder="John Doe"
                required
              />
            </div>
            <div className="form-groups-row">
              <div className="form-groups">
                <label htmlFor="exp_date">Expiration Date*</label>
                <input
                  type="text"
                  id="exp_date"
                  name="exp_date"
                  value={formData.exp_date}
                  onChange={handleExpDateChange}
                  placeholder="MM/YY"
                  maxLength="5"
                  required
                />
              </div>
              <div className="form-groups">
                <label htmlFor="cvv">CVV</label>
                <input
                  type="text"
                  id="cvv"
                  name="cvv"
                  value={formData.cvv}
                  onChange={handleCvvChange}
                  placeholder="123"
                  maxLength="3"
                  required
                />
              </div>
            </div>
            {/* Billing Address Section */}
            <div className="billing-section">
              <h3>Billing Address</h3>
              <div className="form-groups">
                <label htmlFor="billing_address">Address</label>
                <input
                  type="text"
                  id="billing_address"
                  name="billing_address"
                  value={formData.billing_address}
                  onChange={handleChange}
                  placeholder="1234 Main St"
                  required
                />
              </div>
              <div className="form-groups">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  placeholder="Anytown"
                  required
                />
              </div>
              <div className="form-groups-row">
                <div className="form-groups">
                  <label htmlFor="state">State</label>
                  <input
                    type="text"
                    id="state"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    placeholder="State"
                    required
                  />
                </div>
                <div className="form-groups">
                  <label htmlFor="zip_code">Zip Code</label>
                  <input
                    type="text"
                    id="zip_code"
                    name="zip_code"
                    value={formData.zip_code}
                    onChange={handleChange}
                    placeholder="12345"
                    maxLength="10"
                    required
                  />
                </div>
              </div>
            </div>
            <button type="submit" className="submit-button">Book Now</button>
            <p className="secure-payment"><FontAwesomeIcon icon={faLock} /> Secure Payment</p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PaymentForm;
