import React from 'react';
import './LandingPage.css';
import Bookingslanding from '../Bookings/Bookingslanding.jsx';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faPlane, faDollarSign, faHeadset, faUsers, faPhoneAlt, faBuilding } from '@fortawesome/free-solid-svg-icons';
import AtlantaSanFrancisco from '../../Media/Atlanta.jpg';
import AtlantaSeattle from '../../Media/AtlantaS.jpg';
import SanFranciscoNewYork from '../../Media/NY.jpg';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import lastm from '../../Media/lastm.webp'
import stu from '../../Media/stu.webp'
import senior from '../../Media/senior.webp'
import defence from '../../Media/defence.webp'
import profileImage from '../../Media/profileImage.jpg';
import '../HomePage/HomePage.css'
import '../Navbar/Navbar1.jsx'
import Navbar1 from '../Navbar/Navbar1.jsx';

import NumberPopup from '../NumberPopup/NumberPopup.jsx';
import FlightRoutes from '../FlightRoutes/FlightRouts.jsx';
import flightImage from '../../Media/flight.jpg'

const LandingPage = ({ BottomComponent }) => {


    const destinations = [
        {
            image: AtlantaSeattle,
            title: 'ATLANTA',
            dates: 'Aug 08 – Aug 18',
            price: '$99'
        },
        {
            image: AtlantaSanFrancisco,
            title: 'NEW YORK',
            dates: 'Sep 05 – Sep 15',
            price: '$120'
        },
        {
            image: SanFranciscoNewYork,
            title: 'LOS ANGELES',
            dates: 'Oct 01 – Oct 10',
            price: '$150'
        }
        // Add more destinations as needed
    ];


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    const handleClick = () => {
        window.gtag_report_conversion("tel:(509) 517-7230");
        window.location.href = 'tel:(509) 517-7230';
    };



    return (
        <div className="landing-page-container">
            {/* <Navbar1 /> */}
            <Bookingslanding />

{/* 
            <div className="parent-container">
                <div className="landing-page-call-container" onClick={handleClick}>
                    <img
                        src={profileImage}
                        alt="Profile"
                        className="landing-page-call-image"
                    />
                    <span className="landing-page-call-phone-number">(509)517-7230</span>
                    <span className="landing-page-call-icon">📞</span>
                </div>
            </div> */}


            {/* <div className="landing-page-banner-call-container">
      <p className="landing-page-banner-call-text">
       <p  className="landing-page-banner-call-text"> For Best Deals Give Us A Call </p>  
        <span className="landing-page-banner-call-number" onClick={handleClick}>
          (509)517-7230  <FaPhone/>
        </span>
     
      </p>
    </div> */}
            <section className="homepage-features">
                <h2 className="homepage-features-heading">Benefits of Booking With Us</h2>
                <div className="homepage-feature-boxes">
                    <div className="homepage-feature-box">
                        <div className="homepage-feature-icon">
                            <FontAwesomeIcon icon={faPlane} size="3x" />
                        </div>
                        <h3 className="homepage-feature-heading">Best Bookings</h3>
                        <p className="homepage-feature-description">Experience hassle-free booking with our top-notch service.</p>
                    </div>
                    <div className="homepage-feature-box">
                        <div className="homepage-feature-icon">
                            <FontAwesomeIcon icon={faDollarSign} size="3x" />
                        </div>
                        <h3 className="homepage-feature-heading">Best Price</h3>
                        <p className="homepage-feature-description">Enjoy competitive pricing for your flights.</p>
                    </div>
                    <div className="homepage-feature-box">
                        <div className="homepage-feature-icon">
                            <FontAwesomeIcon icon={faHeadset} size="3x" />
                        </div>
                        <h3 className="homepage-feature-heading">24X7 Support</h3>
                        <p className="homepage-feature-description">Get round-the-clock support whenever you need it.</p>
                    </div>
                    <div className="homepage-feature-box">
                        <div className="homepage-feature-icon">
                            <FontAwesomeIcon icon={faShieldAlt} size="3x" />
                        </div>
                        <h3 className="homepage-feature-heading">Trusted Payments</h3>
                        <p className="homepage-feature-description">Secure and trusted payment options for your peace of mind.</p>
                    </div>

                </div>

            </section>

            <section>
            <div className="flight-reservations-assistance-container">
      <div className="flight-reservations-assistance-image-container">
        <img src={flightImage} alt="Flight Assistance" className="flight-reservations-assistance-image" />
      </div>
      <div className="flight-reservations-assistance-text-container">
        <h2 className="flight-reservations-assistance-title">Flight Reservations Assistance</h2>
        <p className="flight-reservations-assistance-text">
          When it comes to handling your travel arrangements efficiently, having quick access to important resources like the airlines reservations number can be incredibly helpful. Whether you need to make changes to your flight booking on the phone, inquire about first class flight bookings, or even deal with unexpected circumstances like flight cancellations, being able to reach out to the someone directly can make the process much smoother. Flight ticket changes can sometimes be a hassle, but with the right support from the customer service team, you can navigate through these issues with ease. In situations where flight cancellations occur, having the reservations number readily available can save you time and stress by allowing you to quickly rebook or explore other options. And for those looking to experience luxury while traveling, booking a first class flight over the phone ensures a seamless and personalized booking process, tailored to your preferences and needs.
        </p>
      </div>
    </div>
            </section>


            


            <div className="travel-by-destination-landingpages">
                <h2>Travel By Destination</h2>
                <p className="fares-updated-landingpages">
                    * Fares displayed on this page are updated as of Jul 04, 2024 at 9:26 AM EST. They are for One Way or Round Trips as shown alongside. These include all Taxes and Fees and our Service Fees. Fares based on historical data can change without notice, and can't be guaranteed until the booking is confirmed. For more details, read our Terms and Conditions carefully.
                </p>
                <Carousel indicators={false}>
                    {destinations.map((destination, index) => (
                        <Carousel.Item key={index}>
                            <img
                                className="d-block w-100"
                                src={destination.image}
                                alt={destination.title}
                            />
                            <Carousel.Caption className="carousel-caption-landingpages">
                                <h3>{destination.title}</h3>
                                <p>{destination.dates}</p>
                                <p className="price-landingpages">from <strong>{destination.price}</strong> per person</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
            <FlightRoutes/>
            <Navbar1 />

            <div className="landing-page-r-container">
                <h2 className="landing-page-r-title">Travel By Theme</h2>
                <p className="landing-page-r-description">
                VGroup Ventures LLC, your travel buddy plans all your travels around any theme that you have in your mind.
                    Be it a last-minute flight or a student discount, plan an affordable trip with our travel agents
                    who will help you throughout the process.
                </p>
                <div className="landing-page-r-carousel">
                    <Slider {...settings}>
                        <div className="landing-page-r-slide">
                            <img src={stu} alt="Student Flights" />
                            <p className="landing-page-r-caption">STUDENT FLIGHTS</p>
                        </div>
                        <div className="landing-page-r-slide">
                            <img src={senior} alt="Student Flights" />
                            <p className="landing-page-r-caption">SENIOR CITIZENS</p>
                        </div>
                        <div className="landing-page-r-slide">
                            <img src={lastm} alt="Student Flights" />
                            <p className="landing-page-r-caption">LAST MINUTE DEALS</p>
                        </div>
                        <div className="landing-page-r-slide">
                            <img src={defence} alt="Military Flights" />
                            <p className="landing-page-r-caption">MILITARY FLIGHTS</p>
                        </div>
                        {/* Add more slides as needed */}
                    </Slider>
                </div>
            </div>

{/* 
            <div className="parent-container">
                <div className="landing-page-call-container" onClick={handleClick}>
                    <img
                        src={profileImage}
                        alt="Profile"
                        className="landing-page-call-image"
                    />
                    <span className="landing-page-call-phone-number">(509)517-7230</span>
                    <span className="landing-page-call-icon">📞</span>
                </div>
            </div> */}

            <NumberPopup/>


        </div>
    );
};

export default LandingPage;
