import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaPlaneDeparture, FaPlaneArrival, FaFilter, FaTimes } from 'react-icons/fa';
import '../SearchResults/SearchResults.css';
import Loading from '../Loading/Loading';

const SearchResults = () => {
  const location = useLocation();
  const searchData = location.state ? location.state.searchData : null;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  const [filters, setFilters] = useState({
    stops: 'all',
    departureTime: 'all',
    arrivalTime: 'all',
    fare: 'all',
  });

  const fetchData = useCallback(async () => {
    if (!searchData) return;
    setLoading(true);
    setError(null); // Reset error before fetching
    try {
      const url = 'https://flight-fare-search.p.rapidapi.com/v2/flights/';
      const options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': '1f005ee062msh1e836e400a98804p178daajsnec9f4f2a6899',
          'X-RapidAPI-Host': 'flight-fare-search.p.rapidapi.com',
        },
      };
      const requestData = {
        from: searchData.from,
        to: searchData.to,
        date: searchData.date,
        adult: searchData.adult,
        child: searchData.child,
        infant: searchData.infant,
        currency: searchData.currency,
      };

      const searchParams = new URLSearchParams(requestData);
      const searchURL = `${url}?${searchParams}`;

      const response = await fetch(searchURL, options);
      if (!response.ok) {
        throw new Error('Failed to fetch flight data');
      }
      const data = await response.json();
      setSearchResults(data.results || []); // Ensure data.results is an array or an empty array
      setFilteredResults(data.results || []); // Same for filtered results
    } catch (error) {
      setError('Failed to fetch flight data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [searchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData, retryCount]); // Re-fetch when retryCount changes

  // Navigate to no-flights page if no results and not loading
  useEffect(() => {
    if (!loading && (!searchResults || searchResults.length === 0)) {
      setTimeout(() => {
        setRetryCount((prevCount) => prevCount + 1); // Retry after a delay
      }, 500); // Delay in milliseconds
    }
  }, [loading, searchResults]);

  useEffect(() => {
    applyFilters();
  }, [filters, searchResults]);

  const applyFilters = () => {
    try {
      let results = [...searchResults];

      // Filter by stops
      if (filters.stops !== 'all') {
        results = results.filter((flight) =>
          filters.stops === 'direct'
            ? flight.stops === 'Direct'
            : flight.stops === `${filters.stops} Stop${filters.stops > 1 ? 's' : ''}`
        );
      }

      // Filter by departure time
      if (filters.departureTime !== 'all') {
        results = results.filter((flight) =>
          checkTimeRange(new Date(flight.departureAirport.time), filters.departureTime)
        );
      }

      // Filter by arrival time
      if (filters.arrivalTime !== 'all') {
        results = results.filter((flight) =>
          checkTimeRange(new Date(flight.arrivalAirport.time), filters.arrivalTime)
        );
      }

      // Sort by fare
      if (filters.fare !== 'all') {
        results.sort((a, b) => {
          const priceA = a.totals.total;
          const priceB = b.totals.total;
          return filters.fare === 'low-to-high' ? priceA - priceB : priceB - priceA;
        });
      }

      setFilteredResults(results);
    } catch (error) {
      console.error('An error occurred while applying filters:', error);
      setError('An error occurred while applying filters. Please try again later.');
    }
  };

  const checkTimeRange = (time, range) => {
    const hour = time.getHours();
    switch (range) {
      case 'night':
        return hour >= 20 || hour < 8;
      case 'morning':
        return hour >= 8 && hour < 12;
      case 'early-morning':
        return hour >= 0 && hour < 8;
      case 'mid-day':
        return hour >= 12 && hour < 16;
      case 'evening':
        return hour >= 16 && hour < 20;
      default:
        return true;
    }
  };
  
  const handlePhoneClick = () => {
    // Google Ads conversion tracking for phone calls
   window.gtag('config', 'AW-16610863777/TfD8CJex_70ZEKHV1vA9', {
      'phone_conversion_number': '(509) 517-7230'
    });
  };



  const handleFilterChange = (filterName, value) => {
    setFilters({ ...filters, [filterName]: value });
  };

  const resetFilters = () => {
    setFilters({
      stops: 'all',
      departureTime: 'all',
      arrivalTime: 'all',
      fare: 'all',
    });
    setFilteredResults(searchResults);
  };

  const toggleFiltersVisible = () => {
    setFiltersVisible(!filtersVisible);
  };

  if (loading) {
    return <div><Loading /></div>;
  }
  if (error) {
    return (
      <div>
        <p>Sorry, something went wrong. {error}</p>
      </div>
    );
  }


  return (
    <div className="main-container oneway-filter-main-container">
      <div className="heading-container oneway-filter-heading-container">
        <div className='flight-cards-container oneway-filter-flight-cards-container enhanced-section'>
          <p>
            Call Now for Exclusive Discounted Flight Deals
            <a
  href="tel:+18664104797"
      onClick={handlePhoneClick}
      className="highlighted-number"
    >
     (866) 410-4797
    </a>
          </p>


        </div>
      </div>
      <div className="filter-toggle" onClick={toggleFiltersVisible}>
        {filtersVisible ? <FaTimes /> : <FaFilter />} Filters
      </div>

      <div className={`filter-content ${filtersVisible ? 'visible' : 'hidden'}`}>
        <div className={`oneway-filter-filters ${filtersVisible ? 'visible' : ''}`}>
          <button onClick={resetFilters} className="reset-button oneway-filter-reset-button">Reset Filter</button>
          <div className="filter-summary">
            <span>Filtered: {filteredResults.length}</span>
            <span>Flights: {searchResults.length}</span>
          </div>
          <div className="oneway-filter-filter-group">
            <div>
              <h4>Stops</h4>
              {['all', 'direct', '1', '2', '3', '4'].map((value) => (
                <label key={value}>
                  <input
                    type="radio"
                    name="stops"
                    value={value}
                    checked={filters.stops === value}
                    onChange={() => handleFilterChange('stops', value)}
                  />
                  {value === 'all' ? 'All' : value === 'direct' ? 'Direct' : `${value} Stop${value > 1 ? 's' : ''}`}
                </label>
              ))}
            </div>
            <div>
              <h4>Departure Time</h4>
              {['all', 'early-morning', 'morning', 'mid-day', 'evening', 'night'].map((value) => (
                <label key={value}>
                  <input
                    type="radio"
                    name="departureTime"
                    value={value}
                    checked={filters.departureTime === value}
                    onChange={() => handleFilterChange('departureTime', value)}
                  />
                  {value.replace(/-/g, ' ')}
                </label>
              ))}
            </div>
            <div>
              <h4>Arrival Time</h4>
              {['all', 'early-morning', 'morning', 'mid-day', 'evening', 'night'].map((value) => (
                <label key={value}>
                  <input
                    type="radio"
                    name="arrivalTime"
                    value={value}
                    checked={filters.arrivalTime === value}
                    onChange={() => handleFilterChange('arrivalTime', value)}
                  />
                  {value.replace(/-/g, ' ')}
                </label>
              ))}
            </div>
            <div>
              <h4>Fare</h4>
              {['all', 'low-to-high', 'high-to-low'].map((value) => (
                <label key={value}>
                  <input
                    type="radio"
                    name="fare"
                    value={value}
                    checked={filters.fare === value}
                    onChange={() => handleFilterChange('fare', value)}
                  />
                  {value.replace(/-/g, ' ')}
                </label>
              ))}
            </div>
          </div>
        </div>
        <div className="flight-cards-container oneway-filter-flight-cards-container">
          {filteredResults.map((flight, index) => (
            <FlightCard key={index} flight={flight} />
          ))}
        </div>
      </div>
    </div>
  );
};


const airlineLogoMapping = {
  "Air India": "https://airlabs.co/img/airline/m/AI.png",
  "Vistara": "https://airlabs.co/img/airline/m/UK.png",
  "Air India Express": "https://airlabs.co/img/airline/m/IX.png",
};

const getAirlineLogoUrl = (airlineName) => {
  return airlineLogoMapping[airlineName] || "https://airlabs.co/img/airline/m/6E.png";
};

const FlightCard = ({ flight }) => {
  const {
    flight_name,
    departureAirport,
    arrivalAirport,
    duration,
    totals,
    stops,
    flight_code,
  } = flight;

  const departureDate = new Date(departureAirport.time);
  const formattedDepartureDate = departureDate.toLocaleDateString('en-US', {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
  });

  const arrivalDate = new Date(arrivalAirport.time);
  const formattedArrivalDate = arrivalDate.toLocaleDateString('en-US', {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
  });

  const formattedDepartureTime = departureDate.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });

  const formattedArrivalTime = arrivalDate.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });

  const totalPrice = Math.round(totals.total * 10) / 10;
  const departureLocation = `${departureAirport.code} - ${departureAirport.city}`;
  const arrivalLocation = `${arrivalAirport.code} - ${arrivalAirport.city}`;
  const airlineLogoUrl = getAirlineLogoUrl(flight_name);
  const navigate = useNavigate();

  const handleBookNow = (flight) => {
    localStorage.setItem('selectedFlight', JSON.stringify(flight));
    navigate('/user_details_form');
  };

  useEffect(() => {
    localStorage.removeItem('selectedFlight');
  }, []);

  return (
    <div className="flight-card">
      <div className="flight-logo_s">
        <div className="flight-logo"><img src={airlineLogoUrl} alt="" /></div>
        <div className='f_name'><span>{flight_name}</span></div>
        <div className='f_name'>{flight_code}</div>
      </div>
      <div className="flight-details">
        <div className='data_fli'>
          <div className='line'>
            <FaPlaneDeparture className="icon" />
            <hr className="separator" />
            <FaPlaneArrival className="icon" />
          </div>
          <div className='flight-path'>
            <div className="from">
              <span><p>{departureLocation}</p></span>
              <span>{formattedDepartureDate}</span>
              <span>{formattedDepartureTime}</span>
            </div>
            <div className="animated-flight-path">
              <div className="stops">
                <span>{stops === "Direct" ? "Direct" : `${stops}`}</span>
              </div>
              <hr />
              <div className="c">
                <span>{duration.text}</span>
              </div>
            </div>
            <div className="to">
              <span><p>{arrivalLocation}</p></span>
              <span>{formattedArrivalDate}</span>
              <span>{formattedArrivalTime}</span>
            </div>
          </div>
        </div>
        <div className="price">
          <span className="price-label">Price</span>
          <span className="price-value">${totalPrice}</span>
          <button onClick={() => handleBookNow(flight)}>Book Now</button>
        </div>
      </div>
    </div>
  );
};

export default SearchResults;
