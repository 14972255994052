import React, { useEffect } from 'react';

const TrustBoxWidget = () => {
  useEffect(() => {
    // Ensure the TrustBox script is loaded
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(document.getElementById('trustbox'), true);
    }
  }, []);

  return (
    <div
      className="trustpilot-widget"
      data-locale="en-US"
      data-template-id="56278e9abfbbba0bdcd568bc"
      data-businessunit-id="666752474b35e495ecf7d49c"
      data-style-height="52px"
      data-style-width="100%"
      id="trustbox"
    >
      <a href="https://www.trustpilot.com/review/vgroupventures.com" target="_blank" rel="noopener noreferrer">
        Trustpilot
      </a>
    </div>
  );
};

export default TrustBoxWidget;
