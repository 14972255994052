import React, { useState } from 'react';
import './NumberPopup.css';
import call from '../../Media/call.png'

const NumberPopup = () => {
    const [isVisible, setIsVisible] = useState(true);

    const handleClose = () => {
        setIsVisible(false);
    }

    if (!isVisible) {
        return null;
    }

    const handlePhoneClick = () => {
        // Google Ads conversion tracking for phone calls
        window.gtag('config', 'AW-16610863777/TfD8CJex_70ZEKHV1vA9', {
          'phone_conversion_number': '(509) 517-7230'
        });
      };

    return (
       
       <div className="bottom-bar"  href="tel:18664104797" onClick={() => window.gtag_report_conversion("tel:(509) 517-7230")}>
                <a href="tel:+18664104797">
  <img src={call} alt="Agent" className="agent-icon" />
</a>
            <div className="popup-content">
                <span className="small-text">Call Now - 24/7 Helpline (Toll Free)</span>
                <a
                href="tel:+18664104797"
                className="contact-numbers"
                onClick={handlePhoneClick}
              >
      <span className="phone-number">(866) 410-4797</span>
    </a>
                {/* <a href="tel:(509) 517-7230" className="contact-numbers" onClick={() => window.gtag_report_conversion("tel:(509) 517-7230")}>
                <span className="phone-number">(509) 517-7230</span>
                </a> */}
            </div>
            {/* <button className="close-button" onClick={handleClose}>X</button> */}
        </div>
    );
}

export default NumberPopup;
