import React, { useState } from 'react';
import '../Bookings/TravelersModal.css';
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';


const TravelersInput = ({ adults, setAdults, children, setChildren, infants, setInfants }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const increaseValue = (field) => {
    switch (field) {
      case 'adults':
        setAdults(adults + 1);
        break;
      case 'children':
        setChildren(children + 1);
        break;
      case 'infants':
        setInfants(infants + 1);
        break;
      default:
        break;
    }
  };

  const decreaseValue = (field) => {
    switch (field) {
      case 'adults':
        if (adults > 1) setAdults(adults - 1);
        break;
      case 'children':
        if (children > 0) setChildren(children - 1);
        break;
      case 'infants':
        if (infants > 0) setInfants(infants - 1);
        break;
      default:
        break;
    }
  };

  return (
    <div className="travelers-input">
      <div className={`travelers-field ${expanded ? 'expanded' : ''}`} onClick={toggleExpanded}>
        Travelers
      </div>
      {expanded && (
        <div className="expanded-travelers">
          <div className="traveler-section">
            <label className='text-to-black'>Adults (12+):</label>
            <div className="input-with-buttons">
              <button onClick={() => decreaseValue('adults')}><FaMinusCircle/></button>
              <input
                type="number"
                min="1"
                value={adults}
                onChange={(e) => setAdults(parseInt(e.target.value))}
              />
              <button onClick={() => increaseValue('adults')}><FaPlusCircle/></button>
            </div>
          </div>
          <div className="traveler-section">
            <label className='text-to-black'>Children (3-12):</label>
            <div className="input-with-buttons">
              <button onClick={() => decreaseValue('children')}><FaMinusCircle/></button>
              <input
                type="number"
                min="0"
                value={children}
                onChange={(e) => setChildren(parseInt(e.target.value))}
              />
              <button onClick={() => increaseValue('children')}><FaPlusCircle/></button>
            </div>
          </div>
          <div className="traveler-section">
            <label className='text-to-black'>Infant (0-2):</label>
            <div className="input-with-buttons">
              <button onClick={() => decreaseValue('infants')}><FaMinusCircle/></button>
              <input
                type="number"
                min="0"
                value={infants}
                onChange={(e) => setInfants(parseInt(e.target.value))}
              />
              <button onClick={() => increaseValue('infants')}><FaPlusCircle/></button>
            </div>
          </div>

          <button onClick={() => setExpanded(false)} className="done-button">Done</button>
        </div>
      )}
    </div>
  );
};

export default TravelersInput;
