
import '../Services/Services.css'; // Import CSS for styling
import React from 'react';
import PTC from '../../Media/PTC.webp'
import efd from '../../Media/efd.webp'
import ctp from '../../Media/ctp.webp'
import csd from '../../Media/247.webp'
import wt from '../../Media/wt.webp'
import htp from '../../Media/htp.webp'

const Services = () => {
  return (
    <div className='serv'>
      <div className="services-container">
        {/* Top Section */}
        <div className="top-section">
          <div className="left-section">
            <h1>Welcome to Our Services</h1>
          </div>

        </div>

        {/* Service Cards Section */}
        <div className="service-cards">
          {/* Service Card 1 */}
          <div className="service-card">
            <img src={PTC} alt="Service 1" />
            <h3>Personalized Travel Consultations</h3>
            <p>Tailored advice and recommendations from experienced travel consultants to help customers plan their trips according to their preferences, budget, and specific requirements.</p>
            {/* <Link to="/WebsiteDevelopment"><button>View More</button></Link> */}
          </div>

          {/* Service Card 2 */}
          <div className="service-card">
            <img src={efd} alt="Service 2" />
            <h3>Exclusive Flight Deals</h3>
            <p>Access to exclusive flight deals and promotions negotiated by the agency with airlines, providing customers with cost-effective options for their travel needs.</p>
            {/* <Link to="/performance_marketing"><button>View More</button></Link> */}
          </div>

          <div className="service-card">
            <img src={ctp} alt="Service 2" />
            <h3>Customized Travel Packages</h3>
            <p> Creation of customized travel packages that include flights, accommodation, transportation, and activities, allowing customers to enjoy a seamless and hassle-free travel experience tailored to their interests and preferences.</p>
            {/* <Link to="/business_consultation">    <button>View More</button></Link> */}
          </div>

          <div className="service-card">
            <img src={csd} alt="Service 3" />
            <h3>24/7 Concierge Service</h3>
            <p>Round-the-clock customer support and assistance from dedicated concierge teams, ensuring that customers receive prompt assistance and solutions to any travel-related issues or emergencies, regardless of the time zone.</p>
            {/* <Link to="/video_editing"><button>View More</button></Link> */}
          </div>

          <div className="service-card">
            <img src={wt} alt="Service 2" />
            <h3>World Tour Planning</h3>
            <p>Comprehensive assistance in planning and organizing world tours, including itinerary creation, visa assistance, accommodation booking, transportation arrangements, and personalized recommendations for must-visit destinations and attractions across multiple countries and continents.</p>
            {/* <Link to="/graphics_design"><button>View More</button></Link> */}
          </div>

          <div className="service-card">
            <img src={htp} alt="Service 2" />
            <h3>Holiday Trip Planning</h3>
            <p>Personalized holiday trip planning tailored to your preferences, featuring themed destinations, events, and activities. We handle all aspects, from accommodations to transportation, ensuring your holiday is memorable and hassle-free.</p>
            {/* <Link to="/lead_generation"><button>View More</button></Link> */}
          </div>


        </div>
      </div>
    </div>

  );
};

export default Services;