import React from 'react';

const DateInput = ({ id, value, onChange, minDate }) => {
  return (
    <input
      type="date"
      id={id}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      min={minDate}
    />
  );
};

export default DateInput;
