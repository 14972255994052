import React from 'react'
import '../Navbar/Navbar1.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';



const Navbar1 = () => {

  const handlePhoneClick = () => {
    // Google Ads conversion tracking for phone calls
    window.gtag('config', 'AW-16610863777/TfD8CJex_70ZEKHV1vA9', {
      'phone_conversion_number': '(509) 517-7230'
    });
  };


  return (
    <div className="N_1_top-header">
      <div className="N_1_text-container">
        <p>* Best Deals at VGroup Ventures LLC *</p>
        <p>
      * <a 
           href="tel:+18664104797"
          className="contact-number" 
          onClick={handlePhoneClick}
        >
          Book online <FontAwesomeIcon icon={faPhone} /> (866) 410-4797
        </a> *
    </p>
        {/* <p>*  <a href="tel: (509) 517-7230" className="contact-number" onClick={() => window.gtag_report_conversion("tel:(509) 517-7230")}>Please give us a call on <FontAwesomeIcon icon={faPhone} /> (509) 517-7230</a>  *</p> */}
        <p>* Greetings and Offers *</p>
      </div>
    </div>
  );
};

export default Navbar1;