import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faRoute, faStar, faPhone, faConciergeBell, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import './CustomizeTrip.css';

const CustomizeTrip = () => {
    return (
        <div className="customize-trip-container">
            <section className="greeting-section">
                <div className="text-and-icon">
                    <div className="text">
                        <h1>Customize Trip with VGroup Ventures LLC</h1>
                        <p>Welcome to VGroup Ventures LLC, your ultimate partner in seamless and stress-free flight bookings. At VGroup Ventures LLC, we understand that every journey is unique, and we are committed to making your travel experience exceptional. Our dedicated team of travel experts is here to assist you with all your flight planning needs, ensuring that every aspect of your trip is tailored to your preferences.</p>
                    </div>
                    {/* <FontAwesomeIcon icon={faPlane} className="icon" /> */}
                </div>
            </section>
            <section className="why-book-section">
                <h2>Why Book with VGroup Ventures LLC?</h2>
                <div className="card-container">
                    <div className="card">
                        <div className="icon">
                            <FontAwesomeIcon icon={faRoute} className="fa-icon" />
                        </div>
                        <div className="text">
                            <h3>Expert Flight Planning for Complex Itineraries</h3>
                            <p>Whether you’re planning a multi-city adventure or a complicated international journey, our experienced travel consultants excel in organizing complex itineraries. We take the hassle out of planning, offering you the best routes and connections to fit your schedule and budget.</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="icon">
                            <FontAwesomeIcon icon={faStar} className="fa-icon" />
                        </div>
                        <div className="text">
                            <h3>Personalized Trip Planning</h3>
                            <p>At VGroup Ventures LLC, we believe in personalized service. We take the time to understand your travel preferences and requirements, crafting a travel plan that aligns perfectly with your desires. From business trips to leisure vacations, we ensure that your travel arrangements are seamless and customized just for you.</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="icon">
                            <FontAwesomeIcon icon={faCheckCircle} className="fa-icon" />
                        </div>
                        <div className="text">
                            <h3>Competitive Pricing and Exclusive Deals</h3>
                            <p>We strive to offer competitive prices and exclusive deals on flights, giving you the best value for your money. Our partnerships with major airlines allow us to provide you with special offers and discounts that you won’t find anywhere else.</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="icon">
                            <FontAwesomeIcon icon={faPhone} className="fa-icon" />
                        </div>
                        <div className="text">
                            <h3>24/7 Customer Support</h3>
                            <p>Travel plans can change, and unexpected situations can arise. That’s why our customer support team is available 24/7 to assist you with any queries or changes to your bookings. We’re here to ensure that your travel experience is smooth from start to finish.</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="icon">
                            <FontAwesomeIcon icon={faConciergeBell} className="fa-icon" />
                        </div>
                        <div className="text">
                            <h3>Comprehensive Travel Solutions</h3>
                            <p>Beyond flight bookings, VGroup Ventures LLC offers a range of travel services to enhance your journey. From hotel reservations and car rentals to travel insurance and visa assistance, we are your one-stop-shop for all your travel needs.</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="icon">
                            <FontAwesomeIcon icon={faCheckCircle} className="fa-icon" />
                        </div>
                        <div className="text">
                            <h3>Commitment to Excellence</h3>
                            <p>Our mission at VGroup Ventures LLC is to provide exceptional travel experiences through meticulous planning and personalized service. We are dedicated to making your journey enjoyable and memorable, from the moment you book your flight until you return home.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="our-promise-section">
                <div className="text-and-icon">
                    <div className="text">
                        <h2>Our Promise</h2>
                        <p>At VGroup Ventures LLC, we are passionate about travel and dedicated to helping you explore the world with ease and confidence. Let us handle the details, so you can focus on creating unforgettable memories. Thank you for choosing VGroup Ventures LLC – where your journey begins.</p>
                    </div>
                    {/* <FontAwesomeIcon icon={faPlane} className="icon" /> */}
                </div>
            </section>
            <section className="contact-section">
                <div className="text-and-icon">
                    <div className="text">
                        <h2>Contact Us</h2>
                        <p>Have questions or need assistance with your travel plans? Contact us today, and let our travel experts help you embark on your next adventure.</p>
                    </div>
                    {/* <FontAwesomeIcon icon={faPhone} className="icon" /> */}
                </div>
            </section>
        </div>
    );
};

export default CustomizeTrip;
