import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlaneDeparture, FaPlaneArrival, FaCalendarAlt } from 'react-icons/fa';
import AirportAutocomplete from './AirportAutocomplete';
import DateInput from './DateInput';
import TravelersInput from './TravelersInput';
import '../Bookings/Bookings.css';
import Loading from '../Loading/Loading.jsx';

const Booking = () => {
  const [flightType, setFlightType] = useState('roundtrip');
  const [fromAirport, setFromAirport] = useState('');
  const [toAirport, setToAirport] = useState('');
  const [departureDate, setDepartureDate] = useState('');
  const [returnDate, setReturnDate] = useState('');
  const [ticketClass, setTicketClass] = useState('economy');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);

  useEffect(() => {
    window.addEventListener('beforeunload', clearLocalStorage);


    return () => {
      window.removeEventListener('beforeunload', clearLocalStorage);
      clearLocalStorage();
    };
  }, []);

  const clearLocalStorage = () => {
    localStorage.removeItem('selectedArrivalFlight');
    localStorage.removeItem('selectedDepartureFlight');
    localStorage.removeItem('selectedFlight');
    localStorage.removeItem('paymentInfo');
    localStorage.removeItem('userData');
    // localStorage.removeItem('requestData');
  };

  const validateForm = () => {
    if (!fromAirport || !toAirport || !departureDate) {
      setErrorMessage('Please fill in all required fields.');
      return false;
    }
    setErrorMessage('');
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    setLoading(true); // Set loading state to true

    try {
      const url = 'https://flight-fare-search.p.rapidapi.com/v2/flights/';
      const requestData = {
        from: fromAirport,
        to: toAirport,
        date: departureDate,
        adult: adults,
        child: children,
        infant: infants,
        currency: 'USD'
      };

      if (flightType === 'roundtrip') {
        requestData.returnDate = returnDate;
      }

      localStorage.setItem('requestData', JSON.stringify(requestData));

      console.log(requestData);
      const searchParams = new URLSearchParams(requestData);
      const searchURL = `${url}?${searchParams}`;

      const options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': '1f005ee062msh1e836e400a98804p178daajsnec9f4f2a6899',
          'X-RapidAPI-Host': 'flight-fare-search.p.rapidapi.com'
        }
      };

      const response = await fetch(searchURL, options);

      if (!response.ok) {
        throw new Error('Failed to fetch flight data');
      }

      const data = await response.json();

      if (flightType === 'roundtrip') {
        const arrivalSearchParams = { ...requestData, from: requestData.to, to: requestData.from };
        const arrivalSearchURL = `${url}?${new URLSearchParams(arrivalSearchParams)}`;
        const arrivalResponse = await fetch(arrivalSearchURL, options);
        const arrivalData = await arrivalResponse.json();

        navigate(`/depart`, { state: { searchData: requestData, results: data.results } });
      } else {
        navigate(`/search_results`, { state: { searchData: requestData, results: data.results } });
      }
    } catch (error) {
      setErrorMessage('Failed to fetch flight data. Please try again later.');
    } finally {
      setLoading(false); // Set loading state to false
    }
  };

  return (
    <div className="booking-container">
      {loading ? (
        <div className="loading-screen">
          <div className="spin"></div>
          {/* <p>Loading, please wait...</p> */}
          <Loading/>
        </div>
      ) : (
        <div className="booking-form">
          <h1>SEARCH FLIGHTS</h1>
          <form onSubmit={handleSubmit}>
            <div className="container-one">
              <div className="form-group-1">
                <label htmlFor="flightTypeDropdown">TripType</label>
                <select
                  id="flightTypeDropdown"
                  value={flightType}
                  onChange={(e) => setFlightType(e.target.value)}
                >
                  <option value="oneway">One Way</option>
                  <option value="roundtrip">Round Trip</option>
                </select>
              </div>

              <div className="form-group-1">
                <label>Travelers</label>
                <TravelersInput
                  adults={adults}
                  setAdults={setAdults}
                  children={children}
                  setChildren={setChildren}
                  infants={infants}
                  setInfants={setInfants}
                  autoComplete="off"
                />
              </div>

              <div className="form-group-1">
                <label>Class</label>
                <select value={ticketClass} onChange={(e) => setTicketClass(e.target.value)}>
                  <option value="economy">Economy</option>
                  <option value="premium-economy">Premium Economy</option>
                  <option value="business">Business Class</option>
                  <option value="first-class">First Class</option>
                </select>
              </div>
            </div>

            <div className="container_two_three">
              <div className="container-two">
                <div className="form-group-2">
                  <label htmlFor="fromAirport">
                    <FaPlaneDeparture /> Departure
                  </label>
                  <AirportAutocomplete id="fromAirport" value={fromAirport} onChange={setFromAirport} />
                </div>

                <div className="form-group-2">
                  <label htmlFor="toAirport">
                    <FaPlaneArrival /> Arrival
                  </label>
                  <AirportAutocomplete id="toAirport" value={toAirport} onChange={setToAirport} />
                </div>
              </div>

              <div className="container-three">
                <div className="form-group">
                  <label htmlFor="departureDate">
                    <FaCalendarAlt /> Departure Date:
                  </label>
                  <DateInput
                    id="departureDate"
                    value={departureDate}
                    onChange={setDepartureDate}
                    minDate={new Date()}
                    onFocus={() => {
                      console.log('Departure Date input field clicked');
                    }}
                  />
                </div>

                {flightType === 'roundtrip' && (
                  <div className="form-group">
                    <label htmlFor="returnDate">
                      <FaCalendarAlt /> Return Date:
                    </label>
                    <DateInput
                      id="returnDate"
                      value={returnDate}
                      onChange={setReturnDate}
                      minDate={departureDate}
                      disabled={flightType !== 'roundtrip'}
                      onFocus={() => {
                        console.log('Return Date input field clicked');
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="search-button-container">
              <button className="button-form" type="submit">Search Flights</button>
            </div>

            {errorMessage && <div className="error-message">{errorMessage}</div>}
          </form>
        </div>
      )}
    </div>
  );
};

export default Booking;
