import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHotel, faSuitcase, faCar, faStar, faMoneyBillAlt, faPhone, faConciergeBell, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import './WhyChooseUs.css';

const WhyChooseUs = () => {
    return (
        <div className="whyChoose-us-container">
            <h2 className="whyChoose-section-title">Why Book with VGroup Ventures LLC?</h2>
            <div className="whyChoose-card-container">
                <div className="whyChoose-card">
                    <div className="whyChoose-icon">
                        <FontAwesomeIcon icon={faHotel} className="whyChoose-fa-icon" />
                    </div>
                    <div className="whyChoose-text">
                        <h3>Expert Hotel Booking Services</h3>
                        <p>Finding the perfect accommodation can be challenging, but our experienced consultants make it effortless. We offer a wide range of hotel options to suit your budget and preferences, ensuring a comfortable stay wherever your destination may be.</p>
                    </div>
                </div>
                <div className="whyChoose-card">
                    <div className="whyChoose-icon">
                        <FontAwesomeIcon icon={faSuitcase} className="whyChoose-fa-icon" />
                    </div>
                    <div className="whyChoose-text">
                        <h3>All-Inclusive Booking Solutions</h3>
                        <p>For those who desire a hassle-free vacation, our all-inclusive booking services cover every detail. From flights and accommodations to meals, activities, and excursions, we create comprehensive packages that allow you to relax and enjoy your trip without any worries.</p>
                    </div>
                </div>
                <div className="whyChoose-card">
                    <div className="whyChoose-icon">
                        <FontAwesomeIcon icon={faCar} className="whyChoose-fa-icon" />
                    </div>
                    <div className="whyChoose-text">
                        <h3>Flight + Hotel + Car Rental Packages</h3>
                        <p>Simplify your travel planning with our bundled packages. We combine flights, hotels, and car rentals to offer you convenience and value. Our team will design a cohesive travel plan that covers all your needs, saving you time and money.</p>
                    </div>
                </div>
                <div className="whyChoose-card">
                    <div className="whyChoose-icon">
                        <FontAwesomeIcon icon={faStar} className="whyChoose-fa-icon" />
                    </div>
                    <div className="whyChoose-text">
                        <h3>Personalized Trip Planning</h3>
                        <p>At VGroup Ventures LLC, we believe in personalized service. We take the time to understand your travel preferences and requirements, crafting a travel plan that aligns perfectly with your desires. From business trips to leisure vacations, we ensure that your travel arrangements are seamless and customized just for you.</p>
                    </div>
                </div>
                <div className="whyChoose-card">
                    <div className="whyChoose-icon">
                        <FontAwesomeIcon icon={faMoneyBillAlt} className="whyChoose-fa-icon" />
                    </div>
                    <div className="whyChoose-text">
                        <h3>Competitive Pricing and Exclusive Deals</h3>
                        <p>We strive to offer competitive prices and exclusive deals on flights, hotels, and car rentals, giving you the best value for your money. Our partnerships with major travel providers allow us to offer special offers and discounts that you won't find anywhere else.</p>
                    </div>
                </div>
                <div className="whyChoose-card">
                    <div className="whyChoose-icon">
                        <FontAwesomeIcon icon={faPhone} className="whyChoose-fa-icon" />
                    </div>
                    <div className="whyChoose-text">
                        <h3>24/7 Customer Support</h3>
                        <p>Travel plans can change, and unexpected situations can arise. That's why our customer support team is available 24/7 to assist you with any queries or changes to your bookings. We're here to ensure that your travel experience is smooth from start to finish.</p>
                    </div>
                </div>
                <div className="whyChoose-card">
                    <div className="whyChoose-icon">
                        <FontAwesomeIcon icon={faConciergeBell} className="whyChoose-fa-icon" />
                    </div>
                    <div className="whyChoose-text">
                        <h3>Comprehensive Travel Solutions</h3>
                        <p>Beyond bookings, VGroup Ventures LLC offers a range of travel services to enhance your journey. From travel insurance and visa assistance to customized itineraries and exclusive experiences, we are your one-stop-shop for all your travel needs.</p>
                    </div>
                </div>
                <div className="whyChoose-card">
                    <div className="whyChoose-icon">
                        <FontAwesomeIcon icon={faCheckCircle} className="whyChoose-fa-icon" />
                    </div>
                    <div className="whyChoose-text">
                        <h3>Commitment to Excellence</h3>
                        <p>Our mission at VGroup Ventures LLC is to provide exceptional travel experiences through meticulous planning and personalized service. We are dedicated to making your journey enjoyable and memorable, from the moment you book your trip until you return home.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhyChooseUs;
