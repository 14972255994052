import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './UserDetails.css';

const UserDetails = () => {
    const navigate = useNavigate();
    const [contactInfo, setContactInfo] = useState({ email: '', phone: '' });
    const [passengers, setPassengers] = useState([]);
    const [flightDetails, setFlightDetails] = useState(null);
    const [totalBill, setTotalBill] = useState(0);

    useEffect(() => {
        const storedFlightDetails = localStorage.getItem('selectedFlight');
        if (storedFlightDetails) {
            setFlightDetails(JSON.parse(storedFlightDetails));
        }

        const storedRequestData = localStorage.getItem('requestData');
        if (storedRequestData) {
            const { adult, child, infant } = JSON.parse(storedRequestData);
            const totalPassengers = adult + child + infant;
            setPassengers(Array.from({ length: totalPassengers }, () => ({ firstName: '', middleName: '', lastName: '', dob: '', gender: '' })));
        }
    }, []);

    useEffect(() => {
        if (flightDetails) {
            const departureTotal = flightDetails ? flightDetails.totals.total : 0;
            setTotalBill(departureTotal);
        }
    }, [flightDetails]);

    const handleContactInfoChange = (e) => {
        const { name, value } = e.target;
        setContactInfo({ ...contactInfo, [name]: value });
    };

    const handlePassengerChange = (index, e) => {
        const { name, value } = e.target;
        const updatedPassengers = [...passengers];
        updatedPassengers[index] = { ...updatedPassengers[index], [name]: value };
        setPassengers(updatedPassengers);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const userData = { contactInfo, passengers };
        localStorage.setItem('userData', JSON.stringify(userData));
        navigate('/payments');
    };

    const renderFlightDetails = (flight, type) => {
        const formatDateTime = (dateTimeString) => {
            const date = new Date(dateTimeString);
            const options = { year: 'numeric', month: 'short', day: 'numeric', weekday: 'short' };
            const timeOptions = { hour: '2-digit', minute: '2-digit' };
            return {
                date: date.toLocaleDateString(undefined, options),
                time: date.toLocaleTimeString(undefined, timeOptions)
            };
        };

        const departure = formatDateTime(flight.departureAirport.time);
        const arrival = formatDateTime(flight.arrivalAirport.time);

        return (
            <div className="flight-cardss">
                <div className="flight-header">
                    <span className="flight-typse">{type}</span>
                    <div className="flight-info">
                        <span className="flight-nam">{flight.flight_name} | {flight.flight_code}</span>
                    </div>
                    <div className="flight-pri">
                        <strong>{flight.currency} {flight.totals.total.toFixed(2)}</strong>
                    </div>
                </div>
                <div className="flight-timing">
                    <div className="flight-start">
                        <span className="flight-date">Start on - {departure.date}</span>
                        <span className="flight-time">{departure.time}</span>
                        <span className="flight-location">{flight.departureAirport.city}, {flight.departureAirport.country.label}</span>
                        <span className="flight-terminal">Terminal {flight.departureAirport.type}</span>
                    </div>
                    <div className="flight-duration">
                        <span>{flight.duration.text}</span>
                    </div>
                    <div className="flight-end">
                        <span className="flight-date">Arrive on - {arrival.date}</span>
                        <span className="flight-time">{arrival.time}</span>
                        <span className="flight-location">{flight.arrivalAirport.city}, {flight.arrivalAirport.country.label}</span>
                        <span className="flight-terminal">Terminal {flight.arrivalAirport.type}</span>
                    </div>
                </div>
            </div>
        );
    };

    const currentYear = new Date().getFullYear();
    const startYear = 1930;
    const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => currentYear - i).filter(year => year < 2025);

    return (
        <div className="user-details-container">
            <div className="flight-details">
                {flightDetails && (
                    <div className="flight-banner">
                        {renderFlightDetails(flightDetails, "Departure")}
                    </div>
                )}
            </div>

            <div className="passenger-info">
                <h2>Passenger Information</h2>
                <p className="important-note">
                    <strong>IMPORTANT:</strong> Each passenger's full name must be entered as it appears on their passport or government issued photo ID. Name changes are not permitted after booking.
                </p>
                <form onSubmit={handleSubmit}>
                    {passengers.map((passenger, index) => (
                        <div key={index} className="passenger-details">
                            <div className="passenger-header">
                                <span className="traveler-number">{index + 1}</span>
                                <span className="traveler-type"></span>
                            </div>
                            <div className="input-group">
                                <div className="input-field">
                                    <label>First Name</label>
                                    <input
                                        type="text"
                                        name="firstName"
                                        value={passenger.firstName}
                                        onChange={(e) => handlePassengerChange(index, e)}
                                        required
                                    />
                                </div>
                                <div className="input-field">
                                    <label>Middle Name</label>
                                    <input
                                        type="text"
                                        name="middleName"
                                        value={passenger.middleName}
                                        onChange={(e) => handlePassengerChange(index, e)}
                                    />
                                </div>
                                <div className="input-field">
                                    <label>Last Name</label>
                                    <input
                                        type="text"
                                        name="lastName"
                                        value={passenger.lastName}
                                        onChange={(e) => handlePassengerChange(index, e)}
                                        required
                                    />
                                </div>
                                <div className="input-field">
                                    <label>Gender</label>
                                    <select
                                        name="gender"
                                        value={passenger.gender}
                                        onChange={(e) => handlePassengerChange(index, e)}
                                        required
                                    >
                                        <option value="">Select Gender</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="other">Other</option>
                                    </select>
                                </div>
                                <div className="input-field">
                                    <label>Date of Birth</label>
                                    <div className="dob-selectors">
                                        <select
                                            name="day"
                                            value={passenger.dob.split('-')[2] || ''}
                                            onChange={(e) => handlePassengerChange(index, { target: { name: 'dob', value: `${passenger.dob.split('-')[0] || ''}-${passenger.dob.split('-')[1] || ''}-${e.target.value}` } })}
                                            required
                                        >
                                            <option value="">Day</option>
                                            {Array.from({ length: 31 }, (_, i) => (
                                                <option key={i + 1} value={i + 1}>{i + 1}</option>
                                            ))}
                                        </select>
                                        <select
                                            name="month"
                                            value={passenger.dob.split('-')[1] || ''}
                                            onChange={(e) => handlePassengerChange(index, { target: { name: 'dob', value: `${passenger.dob.split('-')[0] || ''}-${e.target.value}-${passenger.dob.split('-')[2] || ''}` } })}
                                            required
                                        >
                                            <option value="">Month</option>
                                            {Array.from({ length: 12 }, (_, i) => (
                                                <option key={i + 1} value={i + 1}>{i + 1}</option>
                                            ))}
                                        </select>
                                        <select
                                            name="year"
                                            value={passenger.dob.split('-')[0] || ''}
                                            onChange={(e) => handlePassengerChange(index, { target: { name: 'dob', value: `${e.target.value}-${passenger.dob.split('-')[1] || ''}-${passenger.dob.split('-')[2] || ''}` } })}
                                            required
                                        >
                                            <option value="">Year</option>
                                            {years.map(year => (
                                                <option key={year} value={year}>{year}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className="contact-info">
                        <h2>Contact Details</h2>
                        <div className="input-group">
                            <div className="input-field">
                                <label>Phone</label>
                                <input
                                    type="tel"
                                    name="phone"
                                    value={contactInfo.phone}
                                    onChange={handleContactInfoChange}
                                    required
                                    placeholder="Your Phone Number"
                                />
                            </div>
                            <div className="input-field">
                                <label>Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={contactInfo.email}
                                    onChange={handleContactInfoChange}
                                    required
                                    placeholder="Your Email"
                                />
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="submit-btn">Next</button>
                </form>
            </div>
        </div>
    );
};

export default UserDetails;
