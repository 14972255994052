import React, { useEffect, useState } from 'react';
import SearchResults from '../SearchResults/SearchResults';
import SearchResultss from '../SearchResultss/SearchResultss';
import { useLocation, useNavigate } from 'react-router-dom';
import '../RoundTrip/RoundTrip.css'


const RoundTrip = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchData, setSearchData] = useState(null);
    const [results, setResults] = useState(null);
    const [error, setError] = useState(null);

    const handleSubmit = async (searchData) => {
        try {
            const url = 'https://flight-fare-search.p.rapidapi.com/v2/flights/';
            const searchParams = new URLSearchParams(searchData);
            const searchURL = `${url}?${searchParams}`;

            const options = {
                method: 'GET',
                headers: {
                    'X-RapidAPI-Key': '1f005ee062msh1e836e400a98804p178daajsnec9f4f2a6899',
                    'X-RapidAPI-Host': 'flight-fare-search.p.rapidapi.com'
                }
            };

            const response = await fetch(searchURL, options);

            if (!response.ok) {
                throw new Error('Failed to fetch flight data');
            }

            const data = await response.json();
            setSearchData(searchData);
            setResults(data.results);
        } catch (error) {
            setError('Failed to fetch flight data. Please try again later.');
        }
    };

    useEffect(() => {
        // Check if location state contains searchData and results from handleSubmit
        if (location.state && location.state.searchData && location.state.results) {
            setSearchData(location.state.searchData);
            setResults(location.state.results);
        }
    }, [location.state]);

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className='round_trip'>
            <SearchResults searchData={searchData} results={results} />
            <SearchResultss searchData={searchData} results={results} />
        </div>
    );
};

export default RoundTrip;