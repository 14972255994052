import React from 'react';
import './ContactUs.css'; // Updated CSS import with correct filename
import MapComponent from './MapComponent';


const handlePhoneClick = () => {
  // Google Ads conversion tracking for phone calls
  window.gtag('config', 'AW-16610863777/TfD8CJex_70ZEKHV1vA9', {
    'phone_conversion_number': '(509) 517-7230'
  });
};


class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      subject: '',
      message: ''
    };
  }


  

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here
    console.log('Form submitted:', this.state);
    // You can add code here to send the form data to your backend or perform any other action
  }




  render() {
    return (
      <div className="contact_us_container"> {/* Updated CSS class name */}
        <h2>Contact VGroup Ventures LLC</h2>
        <form onSubmit={this.handleSubmit}>
          <label className="contact_us_label"> {/* Updated CSS class name */}
            Name:
            <input type="text" name="name" value={this.state.name} onChange={this.handleChange} />
          </label>
          <label className="contact_us_label"> {/* Updated CSS class name */}
            Email:
            <input type="email" name="email" value={this.state.email} onChange={this.handleChange} />
          </label>
          <label className="contact_us_label"> {/* Updated CSS class name */}
            Subject:
            <input type="text" name="subject" value={this.state.subject} onChange={this.handleChange} />
          </label>
          <label className="contact_us_label"> {/* Updated CSS class name */}
            Message:
            <textarea name="message" value={this.state.message} onChange={this.handleChange} />
          </label>
          <button type="submit" className="contact_us_submit_button">Submit</button> {/* Updated CSS class name */}
        </form>

        <div className="contact_us_details">
          <h3>Contact Details</h3>
          <p>
      <strong>Customer Service Number:</strong>
      <a
        className='contact-page-phone'
          href="tel:+1(866) 410-4797"
        onClick={handlePhoneClick}
      >
        (866) 410-4797
      </a>
    </p>
          {/* <p><strong>Customer Service Number:</strong><a className='contact-page-phone'  href="tel:(509) 517-7230" onClick={() => window.gtag_report_conversion("tel:(509) 517-7230")} >(509) 517-7230</a></p> */}
          <p><strong>Email:</strong> Support@vgroupventures.com</p>
        </div>

        <div className="contact_us_location">
          <h3>Location</h3>
          <MapComponent />
        </div>

        <div className="contact_us_info">
          <h3>Additional Information</h3>
          <p>Welcome to VGroup Ventures LLC, your premier destination for exceptional flight booking services. With an unwavering commitment to excellence, we stand out as the industry's leading flight booking agency, dedicated to elevating your travel experiences to new heights with unmatched expertise and precision. Our seasoned team specializes in crafting tailored solutions to enhance your journey above the clouds. From cutting-edge strategies to personalized approaches, we're here to deliver seamless and unforgettable travel experiences that surpass your expectations.</p>
        </div>

        {/* <div className="contact_us_images">
  <h3>Images</h3>

</div> */}

      </div>
    );
  }
}

export default ContactPage;
