import React from 'react';
import {  FaPhoneAlt  } from 'react-icons/fa';
import '../Whatsapp/Whatsapp.css'




const Whatsapp = () => {
    // Function to handle clicking on the Call icon
    const handleCallClick = () => {
      // Phone number to redirect for calling
      const phoneNumber = '+1 (509) 517-7230';
  
      // Construct phone call URL
      const callUrl = `tel:${phoneNumber}`;
  
      // Redirect to phone call
      window.location.href = callUrl;
    };
  
    // Function to handle clicking on the WhatsApp icon
    // const handleWhatsappClick = () => {
    //   // Phone number to redirect to WhatsApp
    //   const phoneNumber = '123456789';
  
    //   // Construct WhatsApp URL
    //   const whatsappUrl = `https://wa.me/${phoneNumber}`;
  
    //   // Open WhatsApp chat in a new tab
    //   window.open(whatsappUrl, '_blank');
    // };
  
    return (
      <div>
        {/* WhatsApp Icon */}
        {/* <div className="floating-whatsapp-icon" onClick={handleWhatsappClick}>
          <FaWhatsapp />
        </div> */}
        
        {/* Call Icon */}
        <div className="floating-call-icon" onClick={handleCallClick}>
          <FaPhoneAlt />
        </div>
      </div>
    );
  };
  
  export default Whatsapp;