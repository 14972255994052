import React from 'react';
import './PlaneParadise.css';
import pp from '../../Media/pp.jpg';
import MapComponent from '../ContactUs/MapComponent';
import NumberPopup from '../NumberPopup/NumberPopup';
import Taap from '../../Media/Taap.jpg'
import Taaps from '../../Media/taaps.jpg'

const PlaneParadise = () => {

  const handlePhoneClick = () => {
    // Google Ads conversion tracking for phone calls
    window.gtag('config', 'AW-16610863777/TfD8CJex_70ZEKHV1vA9', {
      'phone_conversion_number': '(509) 517-7230'
    });
    // Initiate phone call
    window.location.href = 'tel:+18664104797';
  };

  return (
    <>
      <div className="plane-paradise-container">
        <img
          src={pp}
          alt="Background"
          className="plane-paradise-background-image"
        />

        

        <div className="plane-paradise-overlay">
        <div className='image-taap'>
        <img src={Taaps} alt="taap" />
        </div>
          <h1 className="plane-paradise-heading">Book with Confidence.</h1>
          <h2 className="plane-paradise-title">Affiliated with Expedia TAAP</h2>
          {/* <p className="plane-paradise-subtitle">24/7 Reservation Desk </p> */}
          <p className="plane-paradise-title">24/7 Reservation Desk </p>
          <p className="plane-paradise-title">Book Flights, Hotels & Vacations Packages</p>
          {/* <p className="plane-paradise-subtitle">Your Ultimate Travel Companion - VGroup Ventures LLC</p> */}
          <button
            className="plane-paradise-button"
            onClick={handlePhoneClick}
          >
            Call to Grab the Best Deals
          </button>
          
          {/* <h2 className="plane-paradise-title">Expedia TAAP</h2>
          <h2 className="plane-paradise-title">WS82572</h2> */}
          <p className="plane-paradise-subtitle">VGroup Ventures LLC</p>

        </div>
      </div>
      <div className="plane-paradise-content">
        <p>
          At VGroup Ventures LLC, we take pride in being your trusted travel companion, dedicated to providing unparalleled services in the world of travel.
          As a leading travel agency based in  Washington, USA, we specialize in offering a comprehensive range of travel solutions,
          including flight tickets and enticing travel packages that cater to your wanderlust.
        </p>
        <h2>Expedia TAAP Partnership:</h2>
        <p>
          In our commitment to delivering excellence, we are proud to be associated with Expedia TAAP (Travel Agent Affiliate Program).
          This partnership allows us to access a vast array of travel supplies, ensuring that our customers have access to the best deals and options available in the market.
        </p>
        <h2>Flight Tickets and Travel Packages:</h2>
        <p>
          Embark on a journey with VGroup Ventures LLC as we bring you unbeatable deals on flight tickets and curated travel packages.
          Whether you’re seeking a relaxing beach getaway, a cultural exploration, or an adventure-filled escapade, our travel experts are here to craft the perfect itinerary
          that suits your preferences and budget.
        </p>
        <h2>Exclusive Phone-Based Deals:</h2>
        <p>
          At VGroup Ventures LLC, we understand the importance of personalized service. Unlike other travel agencies, we proudly conduct our transactions through direct phone calls.
          This unique approach allows us to tailor our offerings specifically to your needs and preferences, ensuring a bespoke travel experience.
        </p>
        <p>
          While presenting our deals, we may mention the names of airlines associated with the offers to provide transparency and clarity to our valued customers.
          Rest assured, we are not directly connected with any airlines, and our deals are crafted meticulously to guarantee the best value for your money.
        </p>
        <h2>Connect with Us - Your Journey Begins with a Call:</h2>
        <p>
          Ready to embark on your dream vacation? Connect with our travel experts through our Toll-Free number: <a href="tel:+18664104797" className='phone-text' onClick={handlePhoneClick}> (866) 410-4797</a>.
          Our dedicated team is available to assist you with personalized travel advice, exclusive deals, and any queries you may have.
        </p>
        <h2>Global Presence:</h2>
        <p>
          With call centers strategically located in the United States, the Philippines, and India, we ensure around-the-clock accessibility and assistance for our customers.
          No matter where you are in the world, VGroup Ventures LLC is just a phone call away, ready to make your travel dreams a reality.
        </p>
        <p>
          Discover the world with VGroup Ventures LLC – Where Every Journey is an Adventure, and Every Adventure is Tailored to You!
        </p>
        

        <h3>Non-Affiliation with Airlines:</h3>
        <p>
          Please note that Vgroup Ventures LLC is not directly affiliated or partnered with any specific airlines listed or shown on our website. Our relationship is solely with the Expedia TAAP program, 
          which provides access to various airlines’ flight options, enabling us to offer a wide array of choices to our customers.
        </p>
        <h3>Use of Airline Content, Images, or Logos:</h3>
        <p>
          Occasionally, the Website may feature trademark logos, images, or content related to certain airlines. This material is used only for illustrative and informational purposes 
          to showcase flight deals available through our collaboration with these airlines via the Expedia TAAP program. It does not indicate a direct partnership or endorsement by Vgroup Ventures LLC with the airlines in question.
        </p>
        <h3>Booking and Reservations:</h3>
        <p>
          All flight bookings and reservations through our platform are handled using our phone reservation system. While we strive to offer accurate and current information regarding flight details, 
          availability, and promotions, these details may change without notice by the airlines.
        </p>
        <h3>Disclaimer of Liability:</h3>
        <p>
          Vgroup Ventures LLC is not liable for any discrepancies, inaccuracies, or changes in flight information, fares, or promotions provided by the airlines. Any issues related to bookings made through our platform, 
          including schedule changes, cancellations, or travel disruptions, are governed by the terms and conditions of the respective airlines.
        </p>
        <h3>Third-Party Links and Resources:</h3>
        <p>
          The Website may include links or references to third-party websites, services, or resources for informational purposes. Vgroup Ventures LLC does not endorse or assume responsibility for the content, policies, 
          or practices of these third-party sites.
        </p>
        <p>
          By using the services provided on the Website, you agree to the terms outlined in this disclaimer. We recommend reviewing these terms before proceeding with any bookings or transactions.
        </p>
        <p>
          For inquiries, assistance, or reservations, please reach out to us via our phone reservation system.
        </p>
        <p>
          Company Name: VGROUP VENTURES LLC <br />
          Official Website: www.vgroupventures.com <br />
        <a href="tel:+18664104797" className='phone-text' onClick={handlePhoneClick}> Phone: (866) 410-4797</a> <br />
          Address: 587 155th PL NE, Bellevue, WA 98007 | UNITED STATE OF AMERICA
        </p>

        <h2>Affiliation</h2>
        <h3>Our Partnership with Expedia TAAP | Agency Code: WS82572</h3>
        <p>
          At Vgroup Ventures LLC, we are pleased to announce our partnership with the Expedia Travel Agent Affiliate Program (TAAP), a prominent global network that equips travel agencies 
          with access to a comprehensive selection of travel products and services.
        </p>
        <h3>What is Expedia TAAP?</h3>
        <p>
          The Expedia TAAP program is tailored specifically for travel agencies, offering an intuitive platform that provides access to Expedia’s broad array of flights, hotels, car rentals, 
          activities, and more. Through this collaboration, Vgroup Ventures LLC can offer a wide variety of travel choices to our customers at competitive rates.
        </p>
        <h3>How It Works:</h3>
        <ul>
          <li>
            <strong>Access to Global Inventory:</strong> Expedia TAAP grants us direct entry to an extensive range of travel products and services, including flights from airlines around the world.
          </li>
          <li>
            <strong>Competitive Pricing:</strong> With Expedia TAAP, we can present attractive prices and exclusive offers on flights, ensuring our customers benefit from affordable travel options.
          </li>
          <li>
            <strong>User-Friendly Interface:</strong> The platform’s straightforward design allows us to efficiently search, compare, and book flights on behalf of our customers, ensuring a smooth and effortless booking process.
          </li>
          <li>
            <strong>Personalized Support:</strong> Our partnership with Expedia TAAP enables us to offer customized support throughout the booking experience. Whether you have specific preferences or need assistance, our dedicated team is ready to assist.
          </li>
        </ul>
        <h3>Why Choose Vgroup Ventures LLC with Expedia TAAP?</h3>
        <ul>
          <li>
            <strong>Extensive Selection:</strong> Access a broad range of flights and travel options from airlines worldwide.
          </li>
          <li>
            <strong>Competitive Pricing:</strong> Enjoy affordable fares and exclusive deals tailored to your travel needs.
          </li>
          <li>
            <strong>Convenient Booking:</strong> Benefit from a streamlined booking process through our association with Expedia TAAP.
          </li>
          <li>
            <strong>Expert Assistance:</strong> Receive personalized support and expert guidance from our knowledgeable team.
          </li>
        </ul>
        <p>
          Our partnership with Expedia TAAP enhances your booking experience, ensuring that your travel needs are met with both efficiency and value.
        </p>
        <p>
          For more information or to explore our extensive travel options, contact us at
        </p>
        <p>
          Company Name: VGROUP VENTURES LLC <br />
          Official Website: www.vgroupventures.com <br />
          <p>      <a 
          href="tel:+18664104797"
        className='phone-text' 
        onClick={handlePhoneClick}
      >
        Phone: (866) 410-4797
      </a></p><br/>
          {/* Phone: +1-509-517-7230 <br /> */}
          Address: 587 155th PL NE, Bellevue, WA 98007 | UNITED STATE OF AMERICA <br />
          Affiliation: Expedia TAAP | Agency Code: WS82572
        </p>
        <div className='image-taap'>
        <img src={Taap} alt="taap" />
        </div>

        <h2>Disclaimer</h2>
        <p>
          Vgroup Ventures LLC operates the website www.vgroupventures.com (“the Website”) as an independent travel agency.
          We are affiliated with the Expedia Travel Agent Affiliate Program (TAAP), which allows us to handle flight bookings and provide a broad range of flight options to our clients.
        </p>
        <MapComponent />
        <NumberPopup />
      </div>
    </>
  );
};

export default PlaneParadise;
