import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../Confirmation/Confirmation.css';
import tkt from '../../Media/tkt.png';

const Confirmation = () => {
  const [bookingData, setBookingData] = useState(null);
  const isSubmittedRef = useRef(false); // Use ref to track submission status

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const selectedFlight = JSON.parse(localStorage.getItem('selectedFlight'));
    const selectedDepartureFlight = JSON.parse(localStorage.getItem('selectedDepartureFlight'));
    const selectedArrivalFlight = JSON.parse(localStorage.getItem('selectedArrivalFlight'));
    const savedCardDetails = JSON.parse(localStorage.getItem('paymentInfo'));

    let combinedData;

    if (selectedDepartureFlight && selectedArrivalFlight) {
      combinedData = {
        contact_info: {
          email: userData.contactInfo.email,
          phone: userData.contactInfo.phone,
        },
        passengers: userData.passengers.map(passenger => ({
          first_name: passenger.firstName,
          middle_name: passenger.middleName,
          last_name: passenger.lastName,
          dob: passenger.dob,
          gender: passenger.gender,
        })),
        departure_flight: {
          career_code: selectedDepartureFlight.careerCode,
          flight_code: selectedDepartureFlight.flight_code,
          flight_name: selectedDepartureFlight.flight_name,
          stops: selectedDepartureFlight.stops,
          cabin_type: selectedDepartureFlight.cabinType,
          currency: selectedDepartureFlight.currency,
          duration_text: selectedDepartureFlight.duration.text,
          duration_value: selectedDepartureFlight.duration.value,
          total: selectedDepartureFlight.totals.total,
          base: selectedDepartureFlight.totals.base,
          departure_time: selectedDepartureFlight.departureAirport.time,
          departure_code: selectedDepartureFlight.departureAirport.code,
          departure_label: selectedDepartureFlight.departureAirport.label,
          departure_city: selectedDepartureFlight.departureAirport.city,
          arrival_time: selectedDepartureFlight.arrivalAirport.time,
          arrival_code: selectedDepartureFlight.arrivalAirport.code,
          arrival_label: selectedDepartureFlight.arrivalAirport.label,
          arrival_city: selectedDepartureFlight.arrivalAirport.city,
          baggage_allowance_cabin: selectedDepartureFlight.baggage.cabin.allowance,
          baggage_qty_cabin: selectedDepartureFlight.baggage.cabin.qty,
          baggage_unit_cabin: selectedDepartureFlight.baggage.cabin.unit,
          baggage_text_cabin: selectedDepartureFlight.baggage.cabin.text,
          baggage_allowance_checkin: 1,
          baggage_qty_checkin: 1,
          baggage_unit_checkin: 1,
          baggage_text_checkin: 1,
          baggage_total_weight: 1,
          baggage_quantity: 1,
          baggage_options_available: selectedDepartureFlight.baggage.baggageOptionsAvailable,
        },
        return_flight: {
          career_code: selectedArrivalFlight.careerCode,
          flight_code: selectedArrivalFlight.flight_code,
          flight_name: selectedArrivalFlight.flight_name,
          stops: selectedArrivalFlight.stops,
          cabin_type: selectedArrivalFlight.cabinType,
          currency: selectedArrivalFlight.currency,
          duration_text: selectedArrivalFlight.duration.text,
          duration_value: selectedArrivalFlight.duration.value,
          total: selectedArrivalFlight.totals.total,
          base: selectedArrivalFlight.totals.base,
          departure_time: selectedArrivalFlight.departureAirport.time,
          departure_code: selectedArrivalFlight.departureAirport.code,
          departure_label: selectedArrivalFlight.departureAirport.label,
          departure_city: selectedArrivalFlight.departureAirport.city,
          arrival_time: selectedArrivalFlight.arrivalAirport.time,
          arrival_code: selectedArrivalFlight.arrivalAirport.code,
          arrival_label: selectedArrivalFlight.arrivalAirport.label,
          arrival_city: selectedArrivalFlight.arrivalAirport.city,
          baggage_allowance_cabin: selectedArrivalFlight.baggage.cabin.allowance,
          baggage_qty_cabin: selectedArrivalFlight.baggage.cabin.qty,
          baggage_unit_cabin: selectedArrivalFlight.baggage.cabin.unit,
          baggage_text_cabin: selectedArrivalFlight.baggage.cabin.text,
          baggage_allowance_checkin: 1,
          baggage_qty_checkin: 1,
          baggage_unit_checkin: 1,
          baggage_text_checkin: 1,
          baggage_total_weight: 1,
          baggage_quantity: 1,
          baggage_options_available: selectedArrivalFlight.baggage.baggageOptionsAvailable,
        },
        card_details: savedCardDetails,
      };
    } else {
      combinedData = {
        contact_info: {
          email: userData.contactInfo.email,
          phone: userData.contactInfo.phone,
        },
        passengers: userData.passengers.map(passenger => ({
          first_name: passenger.firstName,
          middle_name: passenger.middleName,
          last_name: passenger.lastName,
          dob: passenger.dob,
          gender: passenger.gender,
        })),
        flight: {
          career_code: selectedFlight.careerCode,
          flight_code: selectedFlight.flight_code,
          flight_name: selectedFlight.flight_name,
          stops: selectedFlight.stops,
          cabin_type: selectedFlight.cabinType,
          currency: selectedFlight.currency,
          duration_text: selectedFlight.duration.text,
          duration_value: selectedFlight.duration.value,
          total: selectedFlight.totals.total,
          base: selectedFlight.totals.base,
          departure_time: selectedFlight.departureAirport.time,
          departure_code: selectedFlight.departureAirport.code,
          departure_label: selectedFlight.departureAirport.label,
          departure_city: selectedFlight.departureAirport.city,
          arrival_time: selectedFlight.arrivalAirport.time,
          arrival_code: selectedFlight.arrivalAirport.code,
          arrival_label: selectedFlight.arrivalAirport.label,
          arrival_city: selectedFlight.arrivalAirport.city,
          baggage_allowance_cabin: selectedFlight.baggage.cabin.allowance,
          baggage_qty_cabin: selectedFlight.baggage.cabin.qty,
          baggage_unit_cabin: selectedFlight.baggage.cabin.unit,
          baggage_text_cabin: selectedFlight.baggage.cabin.text,
          baggage_allowance_checkin: 1,
          baggage_qty_checkin: 1,
          baggage_unit_checkin: 1,
          baggage_text_checkin: 1,
          baggage_total_weight: 1,
          baggage_quantity: 1,
          baggage_options_available: selectedFlight.baggage.baggageOptionsAvailable,
        },
        card_details: savedCardDetails,
      };
    }

    setBookingData(combinedData);

    if (!isSubmittedRef.current) { // Only send data if it hasn't been submitted yet
      const apiUrl = selectedDepartureFlight && selectedArrivalFlight
        ? 'https://Vgroup1.pythonanywhere.com/create_round_booking/'
        : 'https://Vgroup1.pythonanywhere.com/bookings/';

      sendDataToAPI(apiUrl, combinedData);
    }
  }, []); // Run only once

  const sendDataToAPI = async (apiUrl, data) => {
    try {
      isSubmittedRef.current = true; // Set submission status to true

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Failed to send data to the server.');
      }

      console.log('Data sent successfully.');
    } catch (error) {
      console.error('Error:', error.message);
      // Handle error state here if necessary
    }
  };

  const handlePhoneClick = () => {
    // Google Ads conversion tracking for phone calls
    window.gtag('config', 'AW-16610863777/TfD8CJex_70ZEKHV1vA9', {
      'phone_conversion_number': '+15095177230',
    });
  };

  return (
    <div className="confirmation">
      <div className="confirmation-header">
        <img src={tkt} alt="Confirmation" />
      </div>
      <div className="confirmation-content">
        <h2>We have received your request!</h2>
        <p>Thank you for booking with us!</p>
        <p>Your reservation is under process. You'll receive your e-ticket on your registered email within 24 hours.</p>
        <Link to="/" className="btn btn-primary">Go back to Home</Link>
        <h3>For any questions or queries please call</h3>
        <p href="tel:+18664104797" onClick={handlePhoneClick} style={{ cursor: 'pointer' }}> +1(866) 410-4797</p>
        <p>Support@vgroupventures.com</p>
      </div>
    </div>
  );
};

export default Confirmation;
