import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import { faPlaneDeparture, faPlaneUp, faCalendarAlt, faUser, faChild, faBaby, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons';
import { FaPlaneDeparture, FaPlaneArrival } from 'react-icons/fa';
import '../SearchResults/SearchResults.css';
import LoadingComponent from '../Loading/Loading';


const SearchResultss = ({ tripType }) => {
  const location = useLocation();
  const searchData = location.state ? location.state.searchData : null;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);

  // Filter state variables
  const [sortStops, setSortStops] = useState('');
  const [sortAirline, setSortAirline] = useState('');
  const [sortPrice, setSortPrice] = useState('min');

  useEffect(() => {
    if (!searchData) return;

    const fetchData = async () => {
      setLoading(true);
      try {
        // Construct the API URL and options
        const url = 'https://flight-fare-search.p.rapidapi.com/v2/flights/';
        const options = {
          method: 'GET',
          headers: {
            'X-RapidAPI-Key': '1f005ee062msh1e836e400a98804p178daajsnec9f4f2a6899',
            'X-RapidAPI-Host': 'flight-fare-search.p.rapidapi.com'
          }
        };
        const requestData = {
          from: tripType === 'oneway' ? searchData.from : searchData.to,
          to: tripType === 'oneway' ? searchData.to : searchData.from,
          date: searchData.date,
          adult: searchData.adult,
          child: searchData.child,
          infant: searchData.infant,
          currency: searchData.currency
        };

        // If it's a round trip, add return date to the request data
        if (tripType === 'roundtrip') {
          requestData.date = searchData.returnDate; // Adjusting the date for the return trip
        }

        // Construct the search URL with query parameters
        const searchParams = new URLSearchParams(requestData);
        const searchURL = `${url}?${searchParams}`;

        // Fetch data from the API
        const response = await fetch(searchURL, options);
        if (!response.ok) {
          throw new Error('Failed to fetch flight data');
        }
        const data = await response.json();
        setSearchResults(data.results);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch flight data. Please try again later.');
        setLoading(false);
      }
    };

    fetchData();
  }, [searchData, tripType]);

  useEffect(() => {
    applyFilters();
  }, [searchResults, sortStops, sortAirline, sortPrice]);

  const applyFilters = () => {
    let filteredData = [...searchResults];

    // Sort by number of stops
    if (sortStops) {
      filteredData = filteredData.sort((a, b) => a.stops - b.stops);
    }

    // Sort by price
    if (sortPrice === 'min') {
      filteredData = filteredData.sort((a, b) => a.totals.total - b.totals.total);
    } else if (sortPrice === 'max') {
      filteredData = filteredData.sort((a, b) => b.totals.total - a.totals.total);
    }

    setFilteredResults(filteredData);
  };

  if (loading) {
    return <div><LoadingComponent/></div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="main-container">
      <div className="heading-container">
        <h2>Search Results</h2>
      </div>
      <div className="filter-flight">
        <div className="filter-container">
          <h1>Filters</h1>
          {/* Number of stops filter */}
          <div className="filter-card">
            <h2>Stops</h2>
            <select value={sortStops} onChange={(e) => setSortStops(e.target.value)}>
              <option value="">None</option>
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </select>
          </div>

          {/* Price filter */}
          <div className="filter-card">
            <h2>Price</h2>
            <select value={sortPrice} onChange={(e) => setSortPrice(e.target.value)}>
              <option value="min">Min to Max</option>
              <option value="max">Max to Min</option>
            </select>
          </div>
        </div>
        <div className="flight-cards-container">
          {filteredResults.map((flight, index) => (
            <FlightCard key={index} flight={flight} />
          ))}
        </div>
      </div>
    </div>
  );
};

// Airline logo mapping
const airlineLogoMapping = {
  "Air India": "https://airlabs.co/img/airline/m/AI.png",
  "Vistara": "https://airlabs.co/img/airline/m/UK.png",
  "Air India Express": "https://airlabs.co/img/airline/m/IX.png",
  // Add more entries as needed for other airlines
};

// Get the airline logo URL based on the airline name
const getAirlineLogoUrl = (airlineName) => {
  return airlineLogoMapping[airlineName] || "https://airlabs.co/img/airline/m/6E.png"; // Provide a default logo if not found
};

// FlightCard component
const FlightCard = ({ flight }) => {
    const {
      flight_name,
      departureAirport,
      arrivalAirport,
      duration,
      totals,
      stops,
      flight_code,
    } = flight;
  
    // Format departure date
    const departureDate = new Date(departureAirport.time);
    const formattedDepartureDate = departureDate.toLocaleDateString('en-US', {
      weekday: 'long', // Short day (e.g., Mon)
      month: 'short',   // Short month (e.g., Jan)
      day: 'numeric',   // Numeric day (e.g., 01)
    });
  
    // Format arrival date
    const arrivalDate = new Date(arrivalAirport.time);
    const formattedArrivalDate = arrivalDate.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
    });
  
    // Format departure time
    const formattedDepartureTime = departureDate.toLocaleTimeString('en-US', {
      hour: '2-digit',   // 2-digit hour (e.g., 01, 12)
      minute: '2-digit', // 2-digit minute (e.g., 00, 59)
    });
  
    // Format arrival time
    const formattedArrivalTime = arrivalDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });
  
    // Other data
    const totalPrice = Math.round(totals.total * 10) / 10;
    const departureLocation = `${departureAirport.code} - ${departureAirport.city}`;
    const arrivalLocation = `${arrivalAirport.code} - ${arrivalAirport.city}`;
    const airlineLogoUrl = getAirlineLogoUrl(flight_name);
    const navigate = useNavigate();
  
    const handleBookNow = (flight) => {
      localStorage.setItem('selectedFlight', JSON.stringify(flight));
      navigate('/user_deatils_form');
    };
  
    return (
      <div className="flight-card">
        <div className="flight-logo_s">
          <div className="flight-logo"><img src={airlineLogoUrl} alt="" /></div>
          <div className='f_name'><span>{flight_name}</span></div>
          <div className='f_name'>{flight_code}</div>
        </div>
        <div className="flight-details">
  
          <div className='data_fli'>
  
  
            <div className='line'>
              <FaPlaneDeparture className="icon" />
              <hr className="separator" />
              <FaPlaneArrival className="icon" />
            </div>
  
            <div className='flight-path'>
              <div className="from">
                <span><p>{departureLocation}</p></span>
                <span>{formattedDepartureDate}</span>
                <span>{formattedDepartureTime}</span>
              </div>
              <div className="animated-flight-path">
                <div className="stops">
                  <span>{stops === "Direct" ? "Direct" : `${stops} stops`}</span>
                </div>
                <hr />
  
                <div className="c">
                  <span>{duration.text}</span>
                </div>
              </div>
              <div className="to">
                <span><p>{arrivalLocation}</p></span>
                <span>{formattedArrivalDate}</span>
                <span>{formattedArrivalTime}</span>
              </div>
            </div>
          </div>
          <div className="price">
            <span className="price-label">Price</span>
            <span className="price-value">${totalPrice}</span>
            <button onClick={() => handleBookNow(flight)}>Book Now</button>
          </div>
        </div>
      </div>
    );
  };

export default SearchResultss;
