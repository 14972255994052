import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './FlightRoutes.css';


const FlightList = () => {
    const [loading, setLoading] = useState(false);
    const [activeCard, setActiveCard] = useState(null);
    const navigate = useNavigate();
  
    const flights = [
      { from: 'London', to: 'Paris', fromCode: 'LHR', toCode: 'CDG', depart: 'Sep 24, 2024', return: 'Sep 26, 2024', price: 340 },
      { from: 'Miami', to: 'Seville', fromCode: 'MIA', toCode: 'SVQ', depart: 'Jul 29, 2024', return: 'Jul 30, 2024', price: 340 },
      { from: 'Toronto', to: 'Greece', fromCode: 'YYZ', toCode: 'ATH', depart: 'Jul 30, 2024', return: 'Aug 02, 2024', price: 340 },
      { from: 'Florida', to: 'Vienna', fromCode: 'MCO', toCode: 'VIE', depart: 'Jul 22, 2024', return: 'Jul 27, 2024', price: 340 },
      { from: 'Texas', to: 'Madrid', fromCode: 'DFW', toCode: 'MAD', depart: 'Jul 23, 2024', return: 'Jul 31, 2024', price: 340 },
      { from: 'Dallas', to: 'Italy', fromCode: 'DFW', toCode: 'FCO', depart: 'Aug 09, 2024', return: 'Aug 13, 2024', price: 340 },
      { from: 'Atlanta', to: 'Cabo San Lucas', fromCode: 'ATL', toCode: 'SJD', depart: 'Jan 24, 2025', return: 'Feb 14, 2025', price: 300 },
      { from: 'Atlanta', to: 'Cancun', fromCode: 'ATL', toCode: 'CUN', depart: 'Nov 27, 2024', return: 'Nov 30, 2024', price: 320 },
      { from: 'Minneapolis', to: 'Salt Lake City', fromCode: 'MSP', toCode: 'SLC', depart: 'Aug 06, 2024', return: 'Aug 27, 2024', price: 220 },
      { from: 'Detroit', to: 'New York', fromCode: 'DTW', toCode: 'JFK', depart: 'Aug 09, 2024', return: 'Aug 17, 2024', price: 250 },
    ];
  
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  
    const handleSubmit = async (fromCode, toCode, index) => {
      setLoading(true);
      setActiveCard(index);
  
      const getTomorrowsDate = () => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        return tomorrow.toISOString().split('T')[0];
      };
  
      const searchData = {
        from: fromCode,
        to: toCode,
        date: getTomorrowsDate(),
        adult: 1,
        child: 0,
        infant: 0,
        currency: 'USD'
      };
  
      try {
        // Save searchData to local storage
        localStorage.setItem('requestData', JSON.stringify(searchData));
        console.log('Data saved to local storage:', searchData);
  
        // Simulate async operation
        await delay(500); // Adjust delay if needed
  
        // Navigate to the SearchResults component with the search parameters
        navigate('/search_results', {
          state: {
            searchData: searchData
          }
        });
      } catch (error) {
        console.error("Error in handleSubmit: ", error);
      } finally {
        setLoading(false);
        setActiveCard(null);
      }
    };
  
    return (
      <div className="routes-flight-router-list">
        {flights.map((flight, index) => (
          <div key={index} className="routes-flight-router-item">
            <div className="routes-flight-router-info">
              <div className="routes-flight-router-route">
                <span className="routes-flight-router-airport-code">{flight.fromCode}</span>
                <span className="routes-flight-router-airport-name">{flight.from}</span>
              </div>
              <div className="routes-flight-router-icon">✈</div>
              <div className="routes-flight-router-route">
                <span className="routes-flight-router-airport-code">{flight.toCode}</span>
                <span className="routes-flight-router-airport-name">{flight.to}</span>
              </div>
            </div>
            <div className="routes-flight-router-dates">
              <span>{flight.depart}</span>
              <span>{flight.return}</span>
            </div>
            <button 
              className={`routes-flight-router-price-button ${loading && activeCard === index ? 'routes-flight-router-loading' : ''}`}
              onClick={() => handleSubmit(flight.fromCode, flight.toCode, index)}
              disabled={loading}
            >
              ${flight.price}
            </button>
          </div>
        ))}
      </div>
    );
  };
  
  export default FlightList;