import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faHome, faInfoCircle, faEnvelope, faWrench } from '@fortawesome/free-solid-svg-icons';
import '../Navbar/Navbar.css';
import logo from '../../Media/Logo.png'

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSolid, setIsSolid] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsSolid(true);
    } else {
      setIsSolid(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const navigate = useNavigate();
  



  return (
    <>

<nav className={`navbar ${isSolid ? 'solid' : ''}`}>
        <a href="/" className="brand-name" onClick={closeMenu}>
          <img src={logo} alt="Advance Techno" className="logo" />
          <div className="brand-text">
            {/* <span className="advance">Advance</span> */}
            <span className="techno">VGroup Ventures LLC</span>
          </div>
        </a>
        <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
          <li><a href="/" onClick={closeMenu}>Home</a></li>
          <li><a href="/about" onClick={closeMenu}>About</a></li>
          <li><a href="/services" onClick={closeMenu}>Services</a></li>
          <li><a href="/contact_us" onClick={closeMenu}>Contact Us</a></li>
        </ul>
        {/* <button className="cta-button" onClick={handleClickCOntact}>Hire us!</button> */}
        <div className="hamburger-menu" onClick={toggleMenu}>
          <div className="lines"></div>
          <div className="lines"></div>
          <div className="lines"></div>
        </div>
      </nav>
      <div className={`side-menu ${isOpen ? 'open' : ''}`}>
        <div className="close-button" onClick={closeMenu}>
          &times;
        </div>
        <ul>
          <li><a href="/" onClick={closeMenu}>Home</a></li>
          <li><a href="/about" onClick={closeMenu}>About</a></li>
          <li><a href="/services" onClick={closeMenu}>Services</a></li>

          <li><a href="/contact_us" onClick={closeMenu}>Contact Us</a></li>
        </ul>
      </div>


    </>
  );
};

export default Navbar;
