import React from 'react'
import '../AnoutUs/AboutUs.css'
import vdbg from '../../Media/bg2.mp4'
import trip1 from '../../Media/about1.webp'
import trip from '../../Media/miss.webp'
import vission from '../../Media/vission.webp'
import CE from '../../Media/CE.webp'
import CI from '../../Media/ci.webp'



export default function AboutUs() {
  return (

    <>
      <div className="about_us">
        <div className="video-container">
          {/* Looping video background */}
          <video autoPlay muted loop id="background-video">
            <source src={vdbg} type="video/mp4" />
          </video>
        </div>
        <div className="content">
          {/* Other content about your company goes here */}
          {/* <h1>About Us</h1> */}
          {/* <p>Your company's story and values can be showcased here.</p> */}
        </div>
      </div>


      <div className='about_us_se'>
        <section className="about_us_section">
          <div className="about_us_banner">
            <div className="about_us_banner_content">
              <h1 className="about_us_banner_heading">ABOUT COMPANY</h1>
              <p>Welcome to VGroup Ventures LLC, your premier destination for exceptional flight booking services. With an unwavering commitment to excellence, we stand out as the industry's leading flight booking agency, dedicated to elevating your travel experiences to new heights with unmatched expertise and precision. Our seasoned team specializes in crafting tailored solutions to enhance your journey above the clouds. From cutting-edge strategies to personalized approaches, we're here to deliver seamless and unforgettable travel experiences that surpass your expectations.</p>
              {/* <button className="about_us_button">Book Your Flight Now</button> */}
            </div>
            <div className="about_us_banner_image">
              <img src={trip1} alt="Flight Booking Banner" className="about_us_image" />
            </div>
          </div>
        </section>
      </div>


      <div className='about_us_our_mission'>
        <section className="about_us_our_mission_section">
          <div className="about_us_our_mission_banner">
            <div className="about_us_our_mission_banner_content">
              <h1 className="about_us_our_mission_banner_heading">OUR MISSION</h1>
              <p>At VGroup Ventures LLC, we're on a mission to redefine the way you experience travel. Picture this: you're not just booking a flight; you're embarking on a journey filled with possibilities, excitement, and unforgettable moments. That's what we're all about – making your travel dreams a reality.</p>
              <p>We believe that travel is more than just getting from point A to point B. It's about discovering new cultures, meeting fascinating people, and creating memories that last a lifetime. That's why we go above and beyond to ensure that every aspect of your journey is seamless, stress-free, and utterly enjoyable. Whether you're jetting off for a weekend getaway or planning the adventure of a lifetime, we've got you covered. From finding the best deals to offering personalized recommendations, our team is here to make your travel experience truly extraordinary.</p>
              {/* <button className="about_us_our_mission_button">Book Your Flight Now</button> */}
            </div>
            <div className="about_us_our_mission_banner_image">
              <img src={trip} alt="Flight Booking Banner" className="about_us_our_mission_image" />
            </div>
          </div>
        </section>
      </div>

      <div className='about_us_our_vission'>
        <section className="about_us_our_vission_section">
          <div className="about_us_our_vission_banner">
            <div className="about_us_our_vission_banner_content">
              <h1 className="about_us_our_vission_banner_heading">OUR VISION</h1>
              <p>Here at VGroup Ventures LLC, our vision is crystal clear: to become the epitome of excellence in the flight booking industry, setting new standards for seamless travel experiences and customer satisfaction. We envision a future where every journey is an adventure waiting to unfold, where booking a flight is not just a transaction but an opportunity to explore, discover, and create lifelong memories.</p>
              <p>Driven by innovation and fueled by passion, we aim to revolutionize the way people travel by offering cutting-edge technologies, personalized services, and unbeatable value. Our goal is to be the go-to choice for travelers worldwide, renowned for our reliability, transparency, and unwavering commitment to exceeding expectations.</p>
              <p>As we chart our course toward becoming the best-growing flight booking agency, we remain steadfast in our dedication to continuous improvement, never settling for anything less than excellence. With a focus on growth, sustainability, and customer-centricity, we aspire to be the trusted partner that travelers turn to time and time again for all their travel needs.</p>
              {/* <p>Join us on this exhilarating journey as we soar to new heights and redefine the future of travel. Together, let's make every flight booking experience an unforgettable adventure</p> */}

              {/* <button className="about_us_our_vission_button">Book Your Flight Now</button> */}
            </div>
            <div className="about_us_our_vission_banner_image">
              <img src={vission} alt="Flight Booking Banner" className="about_us_our_vission_image" />
            </div>
          </div>
        </section>
      </div>


      <div className='about_us_our_mission'>
        <section className="about_us_our_mission_section">
          <div className="about_us_our_mission_banner">
            <div className="about_us_our_mission_banner_content">
              <h1 className="about_us_our_mission_banner_heading">COMMITMENT TO EXCELLENCE</h1>
              <p>At VGroup Ventures LLC, we are deeply committed to excellence in all aspects of our service. From the moment you engage with us, you'll encounter our steadfast dedication to providing unparalleled travel experiences that exceed your every expectation.</p>
              <p>Our team is driven by a passion for excellence, and we take pride in delivering exceptional service with integrity and professionalism. Every member of our team is committed to ensuring that your journey with us is seamless, enjoyable, and truly memorable.</p>
              <p>We are continuously striving for improvement and innovation, embracing the latest technologies and industry best practices to enhance our services. Whether it's optimizing our booking process, enhancing our website functionality, or offering personalized recommendations, we're always looking for ways to elevate your travel experience.</p>
              <p>At VGroup Ventures LLC, excellence is not just a goal – it's our guiding principle. It shapes everything we do, from the way we interact with our customers to the way we fulfill their travel needs. We are dedicated to going above and beyond to ensure that every traveler receives the highest level of service and support.</p>
              {/* <p>Thank you for choosing VGroup Travels. We are committed to exceeding your expectations and providing you with the exceptional travel experiences you deserve.</p> */}
              {/* <button className="about_us_our_mission_button">Book Your Flight Now</button> */}
            </div>
            <div className="about_us_our_mission_banner_image">
              <img src={CE} alt="Flight Booking Banner" className="about_us_our_mission_image" />
            </div>
          </div>
        </section>
      </div>



      <div className='about_us_our_vission'>
        <section className="about_us_our_vission_section">
          <div className="about_us_our_vission_banner">
            <div className="about_us_our_vission_banner_content">
              <h1 className="about_us_our_vission_banner_heading">COMMUNITY INVOLVEMENT</h1>
              <p>At VGroup Ventures LLC, we believe in giving back to the communities we serve. Through our ongoing community involvement initiatives, we strive to make a positive impact on the lives of those around us. Whether it's supporting local charities, organizing volunteer efforts, or participating in environmental conservation projects, we are committed to being responsible corporate citizens. By actively engaging with our communities, we aim to create a brighter future for all.</p>
            </div>
            <div className="about_us_our_vission_banner_image">
              <img src={CI} alt="Flight Booking Banner" className="about_us_our_vission_image" />
            </div>
          </div>
        </section>
      </div>


      



      






    </>






  )
}
