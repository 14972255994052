import React from 'react'
import '../TermCondition/TermCondition.css'

export default function TermCondition() {
    return (
        <div className="privacy-policy-container">
            <h1 className="privacy-policy-title">Terms & Condition</h1>
            <p className="privacy-policy-paragraph">
                To confirm your travel arrangements, VGroup Ventures LLC may send you two types of documents by email:
            </p>
            <p className="privacy-policy-paragraph">
                The itinerary document is sent right after you make your travel reservation. The itinerary lists your travel requests and informs you that VGroup Ventures has transmitted the details of your reservation to the relevant travel supplier(s).
                The e-ticket (or invoice receipt) document is sent once all components of your travel plans have been confirmed by the travel supplier(s). The e-ticket contains your final trip arrangements and is to be used as a proof of booking when traveling.
            </p>




            <h2 className="privacy-policy-subtitle">IMPORTANT INFORMATION</h2>
            <p className="privacy-policy-paragraph">
                Verify: When you receive your itinerary and/or e-ticket, please verify it immediately. If you have any questions, contact our Service Center.
            </p>
            <p className="privacy-policy-paragraph">Print: A printed version of your e-ticket may be required by the authorities to access check-in areas.</p>
            <p className="privacy-policy-paragraph">Check all travel and destination requirements: Please verify that you:</p>
            <p className="privacy-policy-paragraph">Have all necessary entry documents (passport or other photo identification, visa, vaccination, or other requirements) for each destination</p>
            <p className="privacy-policy-paragraph">Meet all safety and security conditions for your destinations during the length your travel Are familiar with local rules related to transportation and travel, including passenger and baggage inspection procedures.</p>
            <h2 className="privacy-policy-subtitle">DISCLOSURE REGARDING TRAVEL SUPPLIERS</h2>
            <p className="privacy-policy-paragraph">Your VGroup Ventures LLC (“Agent”) is acting as an agent for third party travel suppliers named on your travel documents (“Suppliers”) in selling travel related services, or in accepting reservations or bookings for services that are not directly supplied by Agent (such as air and ground transportation, hotel accommodations, meals, tours, cruises, etc.). Agent, therefore, shall not be responsible for breach of contract or any intentional or careless actions or omissions on part of Suppliers that result in any loss, damage, delay, or injury to you, your companions or group members. Unless the term “guaranteed” is specifically stated in writing on ticket, invoice receipt, or reservation, Agent does not guarantee any Suppliers’ rates, bookings, reservations, connections, scheduling, or handling of personal effects. Agent shall not be responsible for any injuries, damages, or losses cause to any traveler in connection with terrorist activities, social or labor unrest, mechanical or construction failures or difficulties, diseases, local laws, climatic conditions, abnormal conditions or developments, or any other actions, omissions, or conditions outside the Agent’s control. By embarking upon his/her travel, the traveler voluntarily assumes all risks involved in such travel, whether expected or unexpected. Agent is not responsible for cancellation of any service/s and/or refunds from any Supplier or carrier that may cease operations. Traveler is hereby warned of the above risks as well as possible travel industry bankruptcies and medical and climatic disruptions, and is advised to obtain appropriate insurance against them. Traveler’s retention of tickets, reservations, or bookings after issuance shall constitute a consent of the above and an agreement on his/her part to convey the contents hereto to his/her travel companions or group members.</p>
            <p className="privacy-policy-paragraph">Changes or cancellations: To change or cancel your flight itinerary, call your travel agency. If canceling, return the unused tickets to us for possible credit or refund where applicable. If you don’t board your flight for any reason, you must call your Service Center or the airline immediately. This is very important for refund purposes. Most special, excursion, and discount tickets have various travel restrictions and actually carry a significant penalty for change or cancellation. Many are nonrefundable; where penalties apply, they can usually exceed the (remaining) value of the ticket. Be sure you understand these restrictions. If you change your plans en route, try to work with your agency so that our database and reporting tools are updated with all changes. If you must work with the airline, ask them to apply the value of your unused ticket toward the purchase of a new ticket. If a refund is due, obtain a receipt from the airline. If you are holding guaranteed reservations that you may wish to change or cancel, notify the Supplier as soon as possible. Deposits and prepayments are subject to the Supplier’s cancellation policy.</p>
            <p className="privacy-policy-paragraph">Insurance: Airline and other Suppliers’ insurance for baggage may have limited coverage. Your personal insurance may not adequately cover losses incurred by cancellation, accident, illness, or stolen or damaged property. We strongly urge you to consider purchasing additional insurance to cover such risks.</p>



            <h2 className="privacy-policy-subtitle">AIR RESERVATIONS</h2>
            <p className="privacy-policy-paragraph">Reconfirmations: We recommend that you reconfirm all flights with the airlines prior to departure and at each point of stopover. This will give the airline opportunity to keep you advised of any changes that may have occurred in your flight. You should reconfirm continuing or return reservations at each point of stopover or your reservations may be cancelled (that may vary by airline):</p>
            <ul className="privacy-policy-list">
                <li>Domestic – at least 24 hours prior to your flight</li>
                <li>International – at least 72 hours prior to your flight</li>
            </ul>
            <p className="privacy-policy-paragraph">
                Photo ID: Increased airport security regulations now require that all passengers present positive photo identification (such as an identification card, a driver’s license or passport) before boarding an aircraft.
            </p>
            <p className="privacy-policy-paragraph">Check-in: Minimum time for check-in and security procedures varies according to airlines, airports and destinations. We suggest the following check-in times (that may vary according to the airline):</p>
            <ul className="privacy-policy-list">
                <li>Domestic – 1 hour prior to departure</li>
                <li>International – 3 hours prior to departure</li>
                <li>Note: Check-in later than these times could result in denied boarding.</li>
            </ul>



            <p className="privacy-policy-paragraph">
                Baggage fees: Airlines may apply and change baggage fees.
            </p>
            <p className="privacy-policy-paragraph">
                Overbooking: Airline flights may be overbooked. A person denied boarding on a flight may be entitled to compensatory payment. The rules for denied boarding compensation are available at all ticket counters and vary from country to country.
            </p>
            <p className="privacy-policy-paragraph">
                Prepaid tickets: If your travel arrangements involve a request for prepaid airline tickets, picked up by the passenger at an airline counter, please note that most airlines charge a mandatory non-refundable service fee.
            </p>
            <p className="privacy-policy-paragraph">
                Lost tickets: Lost, stolen, or destroyed paper tickets must be paid for until a refund is obtained from the issuing airline, subject to an airline-imposed service charge.
            </p>
        </div>
    )
}
